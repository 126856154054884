import { obj } from "./basic.js";

//import $ from "./third-party/jquery-1.12.3.min.js";

const debug = false;

const set_flash = (role, text = "", target) => {
  const flash = $("#Page-Flash");
  const display = $("#Page-Flash-Text");
  const showing = flash.is("is-visible");
  if (!showing) {
    flash.hide().addClass("is-visible");
  }
  const text_to_use = typeof text === "string" ? text : (text ?? []).join("\n");
  display
    .attr("role", role)
    .attr("class", role + "-blurb")
    .html(text_to_use.replace(/[\n]{1}/g, "<br>"));
  if (showing) {
    flash.find("button:first").focus();
  } else {
    flash.fadeIn(125).find("button:first").focus();
  }
  setup_close_flash(target);
};

const close_flash = (func) => {
  const flash = $("#Page-Flash");
  flash.hide().removeClass("is-visible");
  if (func) {
    func();
  }
};

const setup_close_flash = (target) => {
  const flash = $("#Page-Flash");
  $(document)
    .off("keyup")
    .keyup((e) => {
      if (e.which === 27) {
        // ESC
        //console.log("ESC?  Close flash!");
        close_flash();
      }
    });
  $("#Close-Flash")
    .off("click")
    .click((e) => {
      e.preventDefault();
      close_flash(() => {
        if (target) {
          target.focus();
        }
      });
    });
};

const alert = (text, target) => {
  set_flash("alert", text, target);
};
const status = (text) => {
  set_flash("status", text);
};

const spinner = {
  show: () => {
    const spinner = $("#Page-Spinner");
    if (!spinner.is(".is-visible")) {
      if (debug) {
        console.log("spinner.show");
      }
      spinner.addClass("is-visible");
    }
  },
  hide: () => {
    const spinner = $("#Page-Spinner");
    if (spinner.is(".is-visible")) {
      if (debug) {
        console.log("spinner.hide");
      }
      spinner.removeClass("is-visible");
    }
  },
  set_state: (on) => {
    if (on) {
      spinner.show();
    } else {
      spinner.hide();
    }
  },
};

const advise_about_error = (error) => {
  const Errors = {
    0: "Connection failure.  Please try again, later.",
    401: "User not authorized.  Please check that you are logged in.",
    403: "Action forbidden by server.  Please check your permissions.",
    422: "Issue with input.  Please check what you've entered.",
    404: "Page or record not found.  Please try again, later.",
    500: "Error on server.  Please try again, later.",
    501: "Problem with server action requested.  Please try again, later.",
    503: "Problem reaching server.  Please try again, later.",
  };
  const { status } = error;
  const error_message = Errors[status];
  if (status === 500) {
    handle_500_error(error, Errors[status]);
  } else if (status === 422) {
    handle_422_error(error, Errors[status]);
  } else if (error_message !== undefined) {
    alert(error_message);
  } else {
    alert("Unexpected error code #" + status.toString());
    console.log(error.statusCode());
    console.log({ error });
  }
  if (status !== 0) {
    window.load_failure = true;
  }
};

const handle_422_error = (data, fallback) => {
  const error = detailed_422_error(data.responseText);
  if (error.detail) {
    let parts = ["Error:\n"];
    ["error", "detail"].forEach((part) => {
      if (error[part] !== undefined) {
        parts = [...parts, error[part] + "\n"];
      }
    });
    alert(parts.join(""));
  } else {
    alert(fallback);
  }
};

const detailed_422_error = (response) => {
  try {
    return JSON.parse(response);
  } catch (err) {
    return {};
  }
};

const handle_500_error = (data, fallback) => {
  const error = detailed_500_error(data.responseText);
  const dissect = (error, fallback) => {
    const { details } = error;
    if (details) {
      if (typeof details === "object") {
        if (details.length === undefined) {
          return `<pre class="to-left">${JSON.stringify(details)}</pre>`;
        } else {
          return `<pre class="to-left">${details.join("\n")}</pre>`;
        }
      }
      return `<pre>${details}</pre>`;
    }
    return fallback;
  };

  const result = dissect(error, fallback);
  console.log(result);
  alert(result);
};

const detailed_500_error = (response) => {
  console.log(response);
  try {
    return JSON.parse(response);
  } catch (err) {
    return {};
  }
};

const flux_validated_save = (
  proxy,
  validate_message,
  save_action_name,
  store_name = "item"
) => {
  if (debug) {
    console.log("flux_validated_save");
  }

  const the_state = () => proxy.flux.stores[store_name].getState();

  document.activeElement.blur();
  const { changed } = the_state();
  if (!changed) {
    alert("No changes made.");
    return undefined;
  }
  if (window.POSTing) {
    status("Request is already processing; please wait.");
    return undefined;
  }
  proxy.dispatch(validate_message);
  const errors = the_state().errors ?? {};
  return check_validations(errors, proxy.flux.actions[save_action_name]);
};

const check_validations = (errors, success_action) => {
  if (obj.is_empty(errors)) {
    success_action();
  } else {
    const first_error = errors[errors.first_error];
    alert(first_error, $("#" + errors.first_error));
  }
  return errors;
};

const bind_save_shortcut = (act) => {
  $(window).keydown(function (e) {
    if (e.ctrlKey && e.key === "s") {
      e.preventDefault();
      act();
    }
  });
};
const unbind_save_shortcut = () => {
  $(window).unbind("keydown");
};

module.exports = {
  alert,
  flux_validated_save,
  check_validations,
  bind_save_shortcut,
  unbind_save_shortcut,
  status,
  spinner,
  advise_about_error,
};
