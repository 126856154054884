const Anchor = require("./../Anchor.jsx");
const Time = require("./../Time.jsx");

module.exports = ({
    contract,
    link,
    inline,
    displayStyle,
    displayText,
    className,
    remainder
}) => {
    module.exports.displayName = "ContractMention";

    if (contract === undefined) {
        return <span />;
    }

    const id = contract.id.toString();
    const display_text = lib.coalesce(
        displayText,
        build_display_text(displayStyle, contract)
    );

    if (link) {
        const classes = lib.handle_classes(className, "item.link");
        const show_remainder = lib.coalesce(remainder, "");
        return (
            <Anchor
                className={classes}
                href={`${standard.URL.contracts.pages}?item=${
                    contract.id
                }${show_remainder}`}>
                {display_text}
            </Anchor>
        );
    }
    if (inline) {
        return <span className={className}>{display_text}</span>;
    }
    return <div className={className}>{display_text}</div>;
};

const build_display_text = (style, contract) => {
    const DisplayOptions = {
        date: app => {
            return <Time date={contract.date} />;
        },
        summary: app => lib.coalesce(app.summary, "...")
    };

    const cleaned_style = lib.coalesce(style, "").toLowerCase();

    return DisplayOptions.hasOwnProperty(cleaned_style)
        ? DisplayOptions[cleaned_style](contract)
        : DisplayOptions["date"](contract);
};
