import * as basic from "./basic.js";

var BrowsingMethods = {
  getState: function () {
    return {
      position: this.position,
      page_size: this.page_size,
      listing: this.listing,
      filters: this.filters,
      sorts: this.sorts,
      remainder: this.remainder,
    };
  },

  refresh: function (data) {
    this.listing = data[this.request_key].map((item) => {
      return typeof item === "number" ? { id: item } : item;
    });
    this.emit("change");
  },
  updateList: function (data) {
    if (data.debug) {
      console.log(data);
    }
    const { start, window } = data;
    const items = data[this.request_key];
    const lookup = {};
    items.forEach((item) => {
      item.is_gloss = true;
      lookup[item.id] = item;
    });
    const maximum = Math.min(this.listing.length - 1, start + window + 1);
    for (let i = start; i <= maximum; i = i + 1) {
      const item = this.listing[i];
      if (lookup.hasOwnProperty(item.id)) {
        this.listing[i] = lookup[item.id];
      }
    }
    this.emit("change");
  },

  setPosition: function (data) {
    if (data.debug) {
      console.log({ length: this.listing.length, position: this.position });
      console.log(data);
    }
    if (this.listing.length === 0) {
      return;
    }
    if (data.position < this.listing.length) {
      this.position = data.position;
    } else {
      this.position = 0;
    }
    if (data.debug) {
      console.log({ length: this.listing.length, position: this.position });
    }
    this.emit("change");
  },
  setFilters: function (data) {
    this.filters = data.filters;
    this.emit("change");
  },
  setSorts: function (data) {
    this.sorts = data.sorts;
    this.emit("change");
  },
  setRemainder: function (data) {
    this.remainder = data.remainder;
    this.emit("change");
  },
};

var make_view = (item, path) => {
  // Provides a "view" of a basic field or somewhere drilled down into
  // a complex item
  if (typeof path === "string") {
    path = [path];
  }
  var view = item;
  var length = path.length;
  path.forEach((field, i) => {
    if (i < length - 1) {
      view = view[field];
    }
  });
  return { item: view, last_key: path[path.length - 1] };
};

var EditingMethods = {
  change: function () {
    this.changed = true;
    window.app_editing_changed = true;
    this.applyRules();
    this.emit("change");
  },
  updateFieldSilently: function (data) {
    if (data.debug) {
      console.log(data);
      console.log(this.item);
    }
    var view = make_view(this.item, data.field);
    view.item[view.last_key] = data.value;
    this.emit("change");
  },
  updateField: function (data) {
    if (data.debug) {
      console.log(data);
      console.log(this.item);
    }
    var view = make_view(this.item, data.field);
    if (view.item[view.last_key] === data.value) {
      return;
    }
    view.item[view.last_key] = data.value;
    this.change();
  },

  toggleItemEditInField: function (data, state_path) {
    if (data.debug) {
      console.log(data);
      console.log(state_path);
      console.log(this.item[state_path]);
    }
    var view = make_view(this.item, state_path);
    var item_in = view.item[view.last_key][data.index];
    if (data.debug) {
      console.log(item_in);
    }
    item_in.editing = !item_in.editing;
    if (data.debug) {
      console.log(item_in);
    }
    this.changed = true;
    window.app_editing_changed = true;
    this.emit("change");
  },

  addItemToField: function (data, state_path, data_field, key_field = "") {
    if (data.debug) {
      console.log(data);
    }
    var item_in = data[data_field];
    if (key_field !== "") {
      item_in[key_field] = item_in[key_field] || "new";
    }
    var view = make_view(this.item, state_path);
    view.item[view.last_key] = [...view.item[view.last_key], item_in];
    this.change();
  },
  updateItemInField: function (
    raw_data,
    state_path,
    data_field,
    key_field = "id"
  ) {
    var data = basic.obj.clone(raw_data);
    var item_in = data[data_field];
    var index = data.index;
    if (data.debug) {
      console.log(data);
      console.log(state_path + ", " + data_field);
    }
    if (key_field != "" && item_in[key_field] === undefined) {
      item_in[key_field] = "new";
    }
    item_in.editing = false;
    var view = make_view(this.item, state_path);
    view.item[view.last_key][index] = item_in;
    if (data.debug) {
      console.log(data);
    }
    this.change();
  },
  removeItemFromField: function (data, state_path, key_field = "id") {
    if (data.debug) {
      console.log(data);
      console.log(state_path);
    }
    var view = make_view(this.item, state_path);
    var index = data.index;
    var item_in = view.item[view.last_key][index];
    var key = item_in[key_field];

    if (key !== undefined && key.toString() === "new") {
      view.item[view.last_key].splice(index, 1);
    } else {
      item_in.deleted = true;
      item_in.editing = false;
    }
    this.change();
  },

  markItemDeleted: function (data) {
    if (data.debug) {
      console.log(data);
    }
    var view = make_view(this.item, data.field);
    view.item[view.last_key].editing = false;
    view.item[view.last_key].deleted = true;
    this.change();
  },
  removeItem: function (data) {
    // Note, this removes a key/value from object
    if (data.debug) {
      console.log(data);
    }
    var view = make_view(this.item, data.field);
    delete view.item[view.last_key];
    this.change();
  },

  updateFieldItem: function (data) {
    if (data.debug) {
      console.log(data);
    }
    this.item[data.field][data.key] = data.value;
    this.change();
  },

  removeFieldItem: function (data) {
    if (data.debug) {
      console.log(this.item[data.field]);
      console.log(data);
    }
    var target = this.item[data.field][data.key];
    target.deleted = true;
    target.editing = false;
    if (data.debug) {
      console.log(this.item[data.field][data.key]);
    }
    this.change();
  },
  toggleListItemEdit: function (data) {
    if (data.debug) {
      console.log(data);
      console.log(this.item);
    }
    var field = data.field;
    var index = data.index;
    var current = this.item[field][index].editing || false;
    this.item[field].forEach((list_item) => {
      list_item.editing = false;
    });
    this.item[field][index].editing = !current;
    if (data.debug) {
      console.log(this.item[field][index]);
    }
    this.emit("change");
  },
  setID: function (data) {
    this.id = data.id;
    this.emit("change");
  },
  loadData: function (data) {
    this.item = this.annotate(data[this.request_key]);
    this.changed = false;
    if (this.submitted !== undefined) {
      this.submitted = false;
    }
    window.app_editing_changed = false;
    this.errors = {};
    this.warnings = {};
    this.applyRules();
  },
  annotate: function (raw) {
    console.log("Default annotation function");
    return basic.obj.clone(raw);
  },
  load: function (raw_data) {
    if (raw_data.debug) {
      console.log("EditingMethods.load");
      console.log(raw_data);
    }
    const data = {};
    data[this.request_key] = Object.assign(
      {},
      (this.blank_structure || {})[this.request_key],
      raw_data[this.request_key]
    );
    if (raw_data.debug) {
      console.log(data);
      console.log({ request_key: this.request_key });
    }
    this.loadData(data);
    this.initial_state = basic.obj.clone(data);
    this.changed = false;
    window.app_editing_changed = false;
    this.emit("change");
    this.checkReady();
  },
  reload: function (data) {
    if (data.debug) {
      console.log("EditingMethods.reload");
      console.log(data);
      console.log(this.initial_state);
    }
    this.loadData(basic.obj.clone(this.initial_state));
    this.emit("change");
  },

  setRights: function (data) {
    if (data.debug) {
      console.log(data);
    }
    this.user_rights = basic.obj.clone(data.rights);
    this.emit("change");
  },

  setEditingUser: function (data) {
    this.editing_user = Object.assign(
      {},
      this.editing_user || {},
      basic.obj.clone_only(data.user, ["id", "name", "role"])
    );
    this.change();
  },

  getLookup: function (data, lookup_field, data_field) {
    this.lookups[lookup_field] = data[data_field];
    this.checkReady();
  },

  checkReady: function () {
    var proxy = this;
    proxy.ready = true;
    if (this.lookup_fields) {
      this.lookup_fields.forEach((field) => {
        proxy.ready =
          proxy.ready &&
          proxy.lookups[field] !== undefined &&
          proxy.lookups[field].length > 0;
      });
    }
    this.emit("change");
  },
};

const error_adder = (errors) => {
  return (field, text) => {
    errors[field] = text;
    errors.error_sequence = errors.error_sequence || [];
    errors.error_sequence.push(field);
    errors.first_error = errors.first_error || errors.error_sequence[0];
  };
};
const error_or_warning_handler = (warning_cond, error_add, warning_add) => {
  return (spec) => {
    const { field, error, warning } = spec;
    if (warning_cond) {
      warning_add(field, warning);
    } else {
      error_add(field, error);
    }
  };
};

const change_checker = (obj, orig, debug = false) => {
  return (...path) => {
    const { path_lookup } = basic.obj;
    if (debug) {
      console.log({
        obj: path_lookup(obj, path),
        orig: path_lookup(orig, path),
      });
    }
    return path_lookup(obj, path) !== path_lookup(orig, path);
  };
};

module.exports = {
  make_view,
  error_adder,
  error_or_warning_handler,
  change_checker,
  browsing_methods: BrowsingMethods,
  editing_methods: EditingMethods,
};
