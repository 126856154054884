module.exports = ({ name }) => {
  module.exports.displayName = "FullOfferName";

  return (
    <span>
      {name.map((part, i) => {
        return (
          <span>
            {part}
            {i + 1 < name.length ? <span>&mdash;</span> : null}
          </span>
        );
      })}
    </span>
  );
};
