const Field = require("./../Field.jsx");
const Select = require("./../Select.jsx");
const TextInput = require("./../TextInput.jsx");

module.exports = ({
  address,
  updateAction,
  updateOn,
  searchAction,
  showCompany,
  companyPlaceholder = "Company",
  debug = false,
  tabIndex = undefined,
}) => {
  module.exports.displayName = "AddressEditor";
  if (address === undefined) {
    return <span />;
  }
  const base_states = lib.options.all_states();
  const state_options =
    lib.coalesce(address.state, "") === ""
      ? [{ value: "", display: "(State)", actual: {} }, ...base_states]
      : [...base_states];
  const updater = lib.updaters.wrapping({
    object: address,
    action: updateAction,
    object_transform: (addy) => {
      const result = lib.obj.clone(addy);
      result.blank = lib.obj.all_blank(result, [
        "company",
        "street",
        "city",
        "state",
        "zip",
      ]);
      return result;
    },
  });
  const ZIP_code = lib.coalesce(address.zip, "").trim();
  const ZIP_width_class =
    ZIP_code.length > 5 ? "maxcols-padded-3" : "maxcols-padded-2";

  const handleSearchPoint = (e) => {
    if (searchAction !== undefined) {
      searchAction();
    }
  };

  let index = 0 + lib.coalesce(tabIndex, 0);
  const getTabIndex = (i) => {
    if (tabIndex === undefined) {
      return undefined;
    }
    index += 1;
    return index;
  };

  return (
    <div>
      {showCompany === true ? (
        <Field className="field.multi maxcols-10">
          <TextInput
            name="address_company"
            className="field.part maxcols-padded-10"
            placeholder={companyPlaceholder}
            value={address.company}
            updateAction={updater("company")}
            updateOn={updateOn}
            onBlur={searchAction}
            tabIndex={getTabIndex()}
          />
        </Field>
      ) : (
        <span />
      )}
      <Field className="field.multi maxcols-10">
        <TextInput
          name="address_street"
          className="field.part maxcols-padded-10"
          placeholder="Street Address"
          value={address.street}
          updateAction={updater("street")}
          updateOn={updateOn}
          onBlur={searchAction}
          tabIndex={getTabIndex()}
        />
      </Field>
      <Field className="field.multi maxcols-13">
        <TextInput
          name="address_city"
          className="field.part maxcols-padded-5"
          placeholder="City"
          value={address.city}
          updateAction={updater("city")}
          updateOn={updateOn}
          tabIndex={getTabIndex()}
        />
        <Select
          name="address_state"
          className="field.part maxcols-padded-5"
          options={state_options}
          value={address.state}
          updateAction={updater("state")}
          tabIndex={getTabIndex()}
        />
        <TextInput
          name="address_zip"
          className={`field.part ${ZIP_width_class}`}
          placeholder="ZIP"
          value={ZIP_code}
          maxLength="10"
          mask={standard.masks.ZIP}
          updateAction={updater("zip")}
          updateOn={updateOn}
          tabIndex={getTabIndex()}
        />
      </Field>
    </div>
  );
};
