const NoteDisplay = require("./NoteDisplay.jsx");


module.exports = ({notes}) => {
	module.exports.displayName = "NotesList";
	
    return (
        <ul className="notes-list maxcols-12 middle">
			{
				notes.map((note, i) => {
					const key = "note-" + note.id.toString();
					return (
						<li className="notes-item" key={key}>
							{i > 0 ? <br /> : <span />}
							<NoteDisplay note={note} />
						</li>
					);
				})
			}
        </ul>
	);
}
