const React = require("react");

const Select = require("./Select.jsx");
const StillForm = require("./StillForm.jsx");

module.exports = React.createClass({
  displayName: "ItemEditingControls",
  componentDidMount: function () {
    const field = this.refs.targetField;
    if (field.focus !== undefined) {
      field.focus();
      field.setSelectionRange(0, field.value.length);
    }
  },

  renderInput: function (input_type) {
    const class_name = lib.handle_classes(this.props.inputClass, "field.entry");

    switch (input_type) {
      case "text":
        return (
          <StillForm onSubmit={this.props.onDone}>
            <input
              className={class_name}
              type="text"
              defaultValue={this.props.defaultValue}
              onChange={this.props.renameAction}
              ref="targetField"
            />
          </StillForm>
        );
      case "select":
        return (
          <Select
            id="sub-item-state-change"
            className={class_name}
            value={this.props.defaultValue}
            options={this.props.options || []}
            updateAction={this.props.renameAction}
            ref="targetField"
          />
        );
      default:
        return <span />;
    }
  },

  render: function () {
    const class_name = lib.handle_classes(this.props.inputClass, "field.multi");
    const overall_class_name = class_name.replace("-padded", "");
    const section_class_name = `field.entry ${this.props.inputClass} flush-right`.replace(
      "field.multi ",
      ""
    );

    return (
      <div className={`field.multi ${overall_class_name} flex-this`}>
        {this.renderInput(this.props.input || "text")}

        {React.Children.count(this.props.children) == 0 ? (
          <span />
        ) : (
          <div>
            {React.Children.map(this.props.children, (child) => {
              return child;
            })}
            <br />
          </div>
        )}
        <div className={section_class_name}>
          <button
            className="little-button flush-right"
            onClick={this.props.onDone}
            disabled={this.props.disabled}
          >
            Done
          </button>
        </div>
        <div className={section_class_name}>
          <button
            className="little-button flush-right"
            onClick={this.props.onCancel}
          >
            Cancel
          </button>
        </div>
        {this.props.canDelete ? (
          <div className={section_class_name}>
            <br />
          </div>
        ) : (
          <span />
        )}
        {this.props.canDelete ? (
          <div className={section_class_name}>
            <button
              className="warning-button little-button flush-right"
              onClick={this.props.onDrop}
            >
              Delete
            </button>
          </div>
        ) : (
          <span />
        )}
      </div>
    );
  },
});
