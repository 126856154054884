const Field = require("./../Field.jsx");
const Entry = require("./../Entry.jsx");
const ViewField = require("./../ViewField.jsx");

module.exports = ({contact, fieldClasses}) => {
    module.exports.displayName = "ContactDisplay";

    if (contact === undefined) {
        return <span />;
    }
    const {home_phone, work_phone, mobile_phone, fax, email} = contact;
    const other = lib.coalesce(contact.other, {number: "", name: ""});
    const {blank} = lib.str;
    const all_blank = [
        home_phone,
        work_phone,
        mobile_phone,
        fax,
        other === undefined ? "" : lib.coalesce(other.number, ""),
        email
    ].every(f => blank(f));
    if (all_blank) {
        return (
            <Field className={fieldClasses + " field.multi"}>
                <Entry className="field.part text">
                    <i>(blank)</i>
                </Entry>
            </Field>
        );
    }
    return (
        <div>
            <ViewField
                showOnlyIf={!blank(home_phone)}
                label="Home:"
                entry={home_phone}
                entryClassName="maxcols-padded-5"
            />
            <ViewField
                showOnlyIf={!blank(work_phone)}
                label="Work:"
                entry={work_phone}
                entryClassName="maxcols-padded-5"
            />
            <ViewField
                showOnlyIf={!blank(mobile_phone)}
                label="Mobile:"
                entry={mobile_phone}
                entryClassName="maxcols-padded-5"
            />
            <ViewField
                showOnlyIf={!blank(fax)}
                label="Fax:"
                entry={fax}
                entryClassName="maxcols-padded-5"
            />
            <ViewField
                showOnlyIf={other !== undefined && !blank(other.number)}
                label={lib.coalesce(other.name, "Other") + ":"}
                entry={other.number}
                entryClassName="maxcols-padded-5"
            />
            <ViewField
                showOnlyIf={!blank(email)}
                label="Email:"
                entry={email}
                entryClassName="maxcols-padded-9"
            />
        </div>
    );
};
