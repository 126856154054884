const FieldGroup = require("./../../FieldGroup.jsx");
const Field = require("./../../Field.jsx");
const Label = require("./../../Label.jsx");
const Entry = require("./../../Entry.jsx");

const SourceMention = require("./../SourceMention.jsx");

const SourceSeries = require("./SourceSeries.jsx");

const SourceWrap = ({ entry, errors, updateAction, toggles, toggleAction }) => {
  SourceWrap.displayName = "SourceWrap";
  const { source, series } = entry;
  const { origin, type, territory } = source;
  const { level, entries } = series;
  const updater = lib.updaters.wrapping({
    object: entry,
    action: updateAction,
  });
  const source_text = `${origin.name} (${type.name}) ${source.id}`;

  //console.log({entries, territory, toggles, toggleAction});
  //return <span />;

  return (
    <div className="maxcols-40">
      <FieldGroup className="maxcols-40">
        <Field className="maxcols-40">
          <Label text="Source:" />
          <SourceMention
            text={source_text}
            className="field.entry maxcols-padded-25"
            source={source}
            link={true}
          />
        </Field>
        <Field showOnlyIf={entries.length > 1}>
          <Label text="" />
          <Entry>{entries.length} Extensions</Entry>
        </Field>
      </FieldGroup>
      {entries.length > 1 ? <br /> : <span />}

      <SourceSeries
        entries={entries}
        territory={territory}
        toggles={toggles}
        toggleAction={toggleAction}
        updateAction={updater(["series", "entries"])}
        errors={errors}
      />
      <br />
    </div>
  );
};

module.exports = ({ entries, updateAction, errors, toggles, toggleAction }) => {
  module.exports.displayName = "AdSeries";
  const updater = lib.updaters.wrapping({
    object: entries,
    action: updateAction,
  });

  return (
    <FieldGroup className="maxcols-40">
      {entries.map((entry, i) => {
        const key = `ad-series-${entry.source.id}`;
        return (
          <SourceWrap
            entry={entry}
            key={key}
            toggles={toggles}
            toggleAction={toggleAction}
            updateAction={updater(i)}
            errors={errors}
          />
        );
      })}
    </FieldGroup>
  );
};
