const Dump = require("./../../Dump.jsx");
const FieldGroup = require("./../../FieldGroup.jsx");
const Field = require("./../../Field.jsx");
const Label = require("./../../Label.jsx");

const ExtensionMention = require("./../ExtensionMention.jsx");

const ExtensionSeries = require("./ExtensionSeries.jsx");

const ExtensionWrap = ({
  entry,
  territory,
  updateAction,
  errors,
  toggles,
  toggleAction,
}) => {
  ExtensionWrap.displayName = "ExtensionWrap";

  const { extension, series } = entry;
  const { level, entries, start_date } = series;
  const updater = lib.updaters.wrapping({
    object: entry,
    action: updateAction,
  });

  return (
    <div className="maxcols-40">
      <FieldGroup className="maxcols-40">
        <Field>
          <Label text="Extension:" />
          <ExtensionMention
            className="field.entry maxcols-padded-3"
            extension={extension}
            link={true}
          />
        </Field>
      </FieldGroup>
      <ExtensionSeries
        entries={entries}
        startDate={start_date}
        territory={territory}
        toggles={toggles}
        toggleAction={toggleAction}
        updateAction={updater(["series", "entries"])}
        errors={errors}
      />
      <br />
    </div>
  );
};

module.exports = ({
  entries,
  territory,
  errors,
  updateAction,
  toggles,
  toggleAction,
}) => {
  module.exports.displayName = "SourceSeries";
  const updater = lib.updaters.wrapping({
    object: entries,
    action: updateAction,
  });

  //console.log({territory});

  return (
    <FieldGroup className="maxcols-40">
      {entries.map((entry, i) => {
        const key = `source-series-${entry.extension.id}`;
        return (
          <ExtensionWrap
            entry={entry}
            territory={territory}
            errors={errors}
            key={key}
            toggles={toggles}
            toggleAction={toggleAction}
            updateAction={updater(i)}
          />
        );
      })}
    </FieldGroup>
  );
};
