module.exports = (props) => {
	module.exports.displayName = "ImageViewer";
    var handleClose = (e) => {
        e.preventDefault();
		e.target.blur();
        var action = props.closeAction;
        if (action !== undefined) {action();}
    };
    var viewer_classes = (
        "viewer" +
				(props.visible ? " is-visible" : "") +
        " to-center"
    );
    return (
        <div className={viewer_classes}>
            <img src={props.src} alt={props.alt} />
            <br /><br />
            <a className="menu-item.link to-center" onClick={handleClose}>
                Close
            </a>
        </div>
    );
}
