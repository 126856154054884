const Field = require("./../Field.jsx");
const Entry = require("./../Entry.jsx");

const Line = ({ children }) => {
  return (
    <Field className="field.multi maxcols-13">
      <Entry className="field.part maxcols-padded-13 text">{children}</Entry>
    </Field>
  );
};

module.exports = ({ address, className }) => {
  module.exports.displayName = "AddressDisplay";
  const { street, city, state, zip } = address;
  const company = lib.coalesce(address.company, "");
  const { blank } = lib.str;
  const all_blank = [street, city, state, zip].every((f) => blank(f));

  if (all_blank) {
    return (
      <div className={className} style={{ padding: 0 }}>
        <Line>
          <i>(blank)</i>
        </Line>
      </div>
    );
  }
  return (
    <div className={className} style={{ padding: 0 }}>
      {company === "" ? <span /> : <Line>{company}</Line>}
      <Line>{street}</Line>
      <Line>
        {city}, {state}, {zip.trim()}
      </Line>
    </div>
  );
};
