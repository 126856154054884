//import $ from "./third-party/jquery-1.12.3.min.js";
import { coalesce } from "./basic.js";

const debug = false;

const dummy_func = () => {};

const standard_xhr = {
  done: (data, status) => {
    console.log("No 'done' function specified.");
  },
  fail: (err) => {
    // console.log("Advising about error.");
    console.log({ err });
    ui.advise_about_error(err);
  },
  always: () => {
    ui.spinner.hide();
  },
};

const get_cookie = (key) => {
  const cookies = document.cookie.split("; ");
  let parts = [];
  for (i = 0; i < cookies.length; i++) {
    parts = cookies[i].split("=");
    if (parts[0] == key) {
      if (debug) {
        console.log({ key, value: parts[1] });
      }
      return parts[1];
    }
  }
  return undefined;
};

const CRUD = {
  create: (url, data, spec = {}) => {
    if (CRUD.checkPOSTing()) {
      return;
    }
    let { skip_page_commit, done, done_message, fail, suppress } = spec;
    const default_done = (data, status) => {
      api.redirect_to_new(data);
    };
    done = done ?? default_done;
    if (skip_page_commit !== true) {
      window.app_editing_changed = false;
    }
    CRUD.POST(url, data, "create", { done, done_message, fail, suppress });
  },
  read: (url, done, fail, always) => {
    CRUD.GET(url, { done, fail, always });
  },
  update: (url, data, spec = {}) => {
    CRUD.POST(url, data, "update", spec);
  },
  drop: (url, bounce_to) => {
    const spec = {
      done: function (data, status) {
        if (bounce_to !== undefined) {
          location = location.pathname + bounce_to;
        }
      },
    };
    CRUD.DELETE(url, spec);
  },

  checkPOSTing: () => {
    window.POSTing = coalesce(window.POSTing, false);
    return window.POSTing;
  },

  GET: (url, spec) => {
    const { done, fail, always } = spec;
    $.getJSON(url)
      .done(done ?? standard_xhr.done)
      .fail(fail ?? standard_xhr.fail)
      .always(always ?? standard_xhr.always);
  },
  POST: (url, data, key, spec) => {
    if (debug) {
      console.log("Posting...");
    }
    const { done, done_message, fail, always } = spec;
    const wrapped = {};
    wrapped[key] = JSON.stringify(data);
    window.POSTing = true;
    ui.spinner.show();

    $.ajax({
      url: url,
      type: "post",
      data: wrapped,
      headers: { "X-PTS-CSRF-TOKEN": get_cookie("CSRF-TOKEN") },
    })
      .done((data, status) => {
        coalesce(done, standard_xhr.done)(data, status);
        ui.spinner.hide();
        if (!spec.suppress) {
          ui.status(done_message || "Saved!");
        }
      })
      .fail(coalesce(fail, standard_xhr.fail))
      .always(
        always !== undefined
          ? always
          : () => {
              if (debug) {
                console.log("Always.");
              }
              standard_xhr.always();
              window.POSTing = false;
            }
      );
  },
  formPOST: (url, form_data, spec) => {
    if (debug) {
      console.log("Form Posting...");
    }
    const { done, done_message, fail, always } = spec;
    ui.spinner.show();

    $.ajax({
      url: url,
      type: "POST",
      data: form_data,
      headers: { "X-PTS-CSRF-TOKEN": api.get_cookie("CSRF-TOKEN") },
      contentType: false,
      processData: false,
    })
      .done((data, status) => {
        coalesce(done, standard_xhr.done)(data, status);
        ui.spinner.hide();
        if (!spec.suppress) {
          ui.status(done_message || "Saved!");
        }
      })
      .fail(coalesce(fail, standard_xhr.fail))
      .always(
        always !== undefined
          ? always
          : () => {
              if (debug) {
                console.log("Always.");
              }
              standard_xhr.always();
              window.POSTing = false;
            }
      );
  },

  DELETE: (url, spec) => {
    const { done, fail, always } = spec;
    $.ajax({
      url: url,
      type: "DELETE",
      headers: { "X-PTS-CSRF-TOKEN": get_cookie("CSRF-TOKEN") },
      cache: "no-cache",
      redirect: "manual",
    })
      .done((data, status) => {
        coalesce(done, standard_xhr.done)(data, status);
        ui.spinner.hide();
      })
      .fail(coalesce(fail, standard_xhr.fail))
      .always(
        always !== undefined
          ? always
          : () => {
              if (debug) {
                console.log("Always.");
              }
              standard_xhr.always();
              window.POSTing = false;
            }
      );
  },
};

const build_api_list_url = (base_url, store) => {
  const parts = [];
  Object.keys(store.filters).forEach((key) => {
    if (store.filters[key] !== "") {
      parts.push({ name: key, value: store.filters[key] });
    }
  });
  const the_sorts = { name: "sorts", value: store.sorts };
  parts.push(the_sorts);
  return base_url + "?" + $.param(parts);
};

const build_legacy_list = (the_state, parts = []) => {
  const filters = the_state.filters;
  const sorts = the_state.sorts;
  Object.keys(filters).forEach((key) => {
    parts.push({ name: key, value: filters[key] });
  });
  sorts.map((sort, i) => {
    const words = sort.split(" ");
    const num = (i + 1).toString();
    parts.push({ name: "sort_" + num, value: words[0] });
    if (words.length > 1 && words[1] == "desc") {
      parts.push({ name: "sortDesc_" + num, value: "on" });
    }
  });
  return $.param(parts);
};

const build_legacy_list_url = (page_url, the_state, name_to_use = "browse") => {
  const filters = the_state.filters;
  const sorts = the_state.sorts;
  const parts = [{ name: name_to_use }];
  return page_url + "?" + build_legacy_list(the_state, parts);
};

const build_legacy_list_remainder = (the_state) => {
  const filters = the_state.filters;
  const sorts = the_state.sorts;
  return "&" + build_legacy_list(the_state, []);
};

const get_form_fields = (form) => {
  const result = {};
  const fields = {};
  form.serializeArray().map((field, i) => {
    if (result.hasOwnProperty(field.name)) {
      result[field.name] += "," + field.value;
    } else {
      result[field.name] = field.value;
    }
  });
  return result;
};

const pull_selection_data = (proxy, url, action_name) => {
  $.getJSON(url)
    .done(function (data) {
      proxy.dispatch(action_name, data);
    })
    .fail(function (err) {
      ui.spinner.hide();
    });
};

const browsing_form_handler = (flux, listing_store_name) => {
  return (e) => {
    e.preventDefault();

    const { actions, stores } = flux;
    const the_state = stores[listing_store_name].getState();

    const form = $(e.currentTarget);
    const controls = api.read_browsing_controls(form);
    const remainder = api.build_legacy_list_remainder(controls);
    if (the_state.remainder === "") {
      api.push_state_to(`?browse=${remainder}${location.hash}`);
    } else {
      api.push_state_to(`?browse=${remainder}`);
    }

    actions.set_filters(controls.filters);
    actions.set_sorts(controls.sorts);
    actions.set_remainder(remainder);
    actions.refresh_list();
    actions.change_position();
  };
};

const read_browsing_controls = (form) => {
  const fields = get_form_fields(form);
  const filters = {};
  const sorts = [];
  Object.keys(fields).map((name, i) => {
    const parts = name.split("_");
    const value = fields[name];
    if (name == "browse") {
    } else if (parts[0] == "sort" || parts[0] == "sortDesc") {
      if (parts[0] == "sort") {
        let sort = value;
        if (fields.hasOwnProperty("sortDesc_" + parts[1])) {
          sort += " desc";
        }
        sorts.push(sort);
      }
    } else if (value !== "") {
      filters[name] = value;
    }
  });
  return { filters: filters, sorts: sorts };
};

const push_state_to = (url) => {
  window.history.pushState({ path: url }, "", url);
};

const redirect_to = (query_and_or_hash) => {
  const cut = (source, sep, index) => {
    return coalesce(source.split(sep)[index], "");
  };
  const query_of = cut(cut(query_and_or_hash, "?", 1), "#", 0);
  const hash_of = cut(query_and_or_hash, "#", 1);
  location.hash = hash_of;
  location.search = query_of;
};

const redirect_to_new = (data) => {
  // After creating a new item, the api responds with
  // a 201 ("Created") and the url of the new item.
  // For using on a New-Item page.
  window.location.search = data.urls.page.split("?")[1];
};

module.exports = {
  CRUD,

  standard_xhr,
  build_api_list_url,
  build_legacy_list_url,
  build_legacy_list_remainder,
  get_form_fields,
  get_cookie,
  pull_selection_data,
  browsing_form_handler,
  read_browsing_controls,
  push_state_to,
  redirect_to,
  redirect_to_new,
};
