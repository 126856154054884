const Field = require("./Field.jsx");
const Entry = require("./Entry.jsx");
const Label = require("./Label.jsx");


module.exports = ({label, amount}) => {
    module.exports.displayName = "ViewMoney";

    return (
        <Field>
            <Label text={label} className="maxcols-padded-5" />
            <Entry className="maxcols-padded-4 numeric">
                {amount === undefined ? <br /> : lib.money.format(amount)}
            </Entry>
        </Field>
    );
};
