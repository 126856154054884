const Field = require("./../Field.jsx");
const Label = require("./../Label.jsx");
const Select = require("./../Select.jsx");
const Accordion = require("./../Accordion.jsx");
const AccordionDisplay = require("./../AccordionDisplay.jsx");

module.exports = ({
  name,
  useName,
  label,
  spec,
  value,
  toggles,
  toggler,
  fieldSetter,
  compact,
}) => {
  module.exports.displayName = "ActiveAndInactiveField";

  const optionize = (ray) => {
    return ray.map((n) => lib.options.as({ obj: n }));
  };
  const options = {
    active: optionize(spec.active),
    inactive: optionize(spec.inactive),
  };
  const handle_toggle = (name) => {
    return (e) => {
      e.preventDefault();
      toggler(name)();
    };
  };

  const multi_setter = (name, current_value, within_this) => {
    return (these_values) => {
      const other_field_values = get_other_values(current_value, within_this);
      const all_values = [...other_field_values, ...these_values];
      return fieldSetter(all_values);
    };
  };
  const get_other_values = (current_value, within_this) => {
    const this_lookup = {};
    for (const item of within_this) {
      this_lookup[item.id] = "";
    }
    return current_value.filter((c) => this_lookup[c.id] === undefined);
  };
  const clearer = (name, current_value, within_this) => {
    return () => {
      const other_field_values = get_other_values(current_value, within_this);
      return fieldSetter([...other_field_values]);
    };
  };
  const handle_clear = (name, current_value, within_this) => {
    return (e) => {
      clearer(name, current_value, within_this)();
    };
  };

  const size = 8;
  const display = `show inactive ${name}`;
  const width = compact ? 9 : 13;
  const show_inactive = spec.show_inactive ?? true;

  return (
    <Field className={`maxcols-${width}`}>
      <Label text={label} />
      <div className="maxcols-9">
        <Select
          className="maxcols-padded-9"
          name={useName}
          multiple={true}
          size={size}
          options={options.active}
          value={value}
          valueField="id"
          updateAction={multi_setter(name, value, spec.active)}
        />
        <div className="item maxcols-9">
          <a
            className="item.link to-center"
            onClick={handle_clear(name, value, spec.active)}
          >
            Clear
          </a>
        </div>
        {show_inactive ? (
          <div>
            <div className="item maxcols-9">
              <a className="item.link" onClick={handle_toggle(display)}>
                Inactive <AccordionDisplay open={toggles[display]} />
              </a>
            </div>
            <Accordion open={toggles[display]}>
              <Select
                className="maxcols-padded-9"
                name={useName}
                multiple={true}
                size={size}
                options={options.inactive}
                value={value}
                valueField="id"
                updateAction={multi_setter(name, value, spec.inactive)}
              />
              <div className="item maxcols-9">
                <a
                  className="item.link to-center"
                  onClick={handle_clear(name, value, spec.inactive)}
                >
                  Clear
                </a>
              </div>
            </Accordion>
          </div>
        ) : (
          <span />
        )}
      </div>
    </Field>
  );
};
