const Anchor = require("./../Anchor.jsx");

module.exports = ({origin, link, text, inline, className}) => {
    module.exports.displayName = "SourceOriginMention";
    const {name, id} = origin;
    if (link) {
        const classes = lib.handle_classes(className, "item.link");
        return (
            <Anchor
                className={classes}
                href={`${standard.URL.source_origins.pages}?item=${id}`}>
                {name}
            </Anchor>
        );
    }
    if (inline) {
        return <span className={className}>{name}</span>;
    }
    return <div className={className}>{name}</div>;
};
