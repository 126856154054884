var React = require("react");
var Fluxxor = require("fluxxor");

var TerritoryOutlineCompany = require("./TerritoryOutlineCompany.jsx");

module.exports = React.createClass({
    displayName: "TerritoryOutline",
    mixins: [Fluxxor.FluxMixin(React), Fluxxor.StoreWatchMixin("territory")],
    getStateFromFlux: function() {
        return this.getFlux()
            .store("territory")
            .getState();
    },

    render: function() {
        var info = this.state.info.structure;
        var structure = this.state.structure;
        if (lib.obj.is_empty(info)) {
            return <span />;
        }
        if (lib.obj.is_empty(structure)) {
            return <span />;
        }
        return (
            <ul className="list">
                {Object.keys(info).map((company_id, i) => {
                    var company = structure[company_id];
                    var key = "list-company" + company.id.toString();
                    return (
                        <li className="item" key={key}>
                            <TerritoryOutlineCompany
                                id={company.id}
                                name={company.name}
                                info={info[company_id]}
                                structure={company.states}
                            />
                        </li>
                    );
                })}
            </ul>
        );
    }
});
