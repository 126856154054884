const React = require("react");

const FieldGroup = require("./../../FieldGroup.jsx");
const Field = require("./../../Field.jsx");
const Label = require("./../../Label.jsx");
const Entry = require("./../../Entry.jsx");

module.exports = React.createClass({
  displayName: "CostTotals",
  shouldComponentUpdate: function (nextProps, nextState) {
    // On forms with lots of series, this is faster than a stateless version
    const Fields = [
      "budget",
      "ad_fee",
      "placement_fee",
      "cancellation_fee",
      "other_fee",
      "final_cost",
    ];
    const old_costs = this.props.costs;
    const new_costs = nextProps.costs;
    return (
      Fields.some((f) => old_costs[f] !== new_costs[f]) ||
      this.props.showNoteColumn !== nextProps.showNoteColumn
    );
  },

  render: function () {
    const { costs, showOnlyIf, showNoteColumn } = this.props;
    if (!lib.coalesce(showOnlyIf, false)) {
      return <span />;
    }
    const { format } = lib.money;

    return (
      <FieldGroup
        className={
          (showNoteColumn ? "maxcols-29" : "maxcols-23") + " flush-right"
        }
      >
        <FieldGroup className="maxcols-23 flush-left">
          <Field className="maxcols-4">
            <Label
              className="maxcols-padded-4 to-right emphasized"
              text="Totals:"
            />
          </Field>
          <Field className="maxcols-4">
            <Entry className="maxcols-padded-3 numeric total">
              {format(costs.budget)}
            </Entry>
          </Field>
          <Field className="maxcols-3">
            <Entry className="maxcols-padded-3 numeric total">
              {format(costs.ad_fee)}
            </Entry>
          </Field>
          <Entry className="maxcols-padded-3 numeric total">
            {format(costs.placement_fee)}
          </Entry>
          <Entry className="maxcols-padded-3 numeric total">
            {format(costs.cancellation_fee)}
          </Entry>
          <Entry className="maxcols-padded-3 numeric total">
            {format(costs.other_fee)}
          </Entry>
          <Entry className="maxcols-padded-3 numeric total">
            {format(costs.final_cost)}
          </Entry>
        </FieldGroup>
      </FieldGroup>
    );
  },
});
