const TerritoryOutlineState = require("./TerritoryOutlineState.jsx");

module.exports = (props) => {
	module.exports.displayName = "TerritoryOutlineCompany";
    return (
        <div>
            <span className="item.stub">
                {props.name || "UNNAMED ID #" + props.id.toString()}
                
            </span>
            <ul className="list">
                {
                    Object.keys(props.info).map((state_id, i) => {
                        var state = props.structure[state_id];
                        var key = (
                            "list-company-state-" + props.id.toString() + "-" +
                            state.id
                        );
                        return (
                            <li className="item" key={key}>
                                <TerritoryOutlineState
                                    id={state.id} name={state.name}
                                    info={props.info[state_id]}
                                    structure={state.markets} />
                            </li>
                        );
                    })
                }
            </ul>
        </div>
    );
}
