const AccordionDisplay = require("./../../AccordionDisplay.jsx");
const Anchor = require("./../../Anchor.jsx");
const Button = require("./../../Button.jsx");
const DateEntry = require("./../../DateEntry.jsx");
const Dump = require("./../../Dump.jsx");
const FieldGroup = require("./../../FieldGroup.jsx");
const Field = require("./../../Field.jsx");
const Label = require("./../../Label.jsx");
const Entry = require("./../../Entry.jsx");
const TextInput = require("./../../TextInput.jsx");

const ExtensionMention = require("./../ExtensionMention.jsx");
const ProjectMention = require("./../ProjectMention.jsx");

const CostTotals = require("./CostTotals.jsx");
const ExtensionSeriesHeader = require("./ExtensionSeriesHeader.jsx");
const PlacementsHeader = require("./PlacementsHeader.jsx");
const PlacementsList = require("./PlacementsList.jsx");

const sum_costs = (things) => {
  const Fields = [
    "budget",
    "ad_fee",
    "placement_fee",
    "cancellation_fee",
    "other_fee",
    "final_cost",
  ];
  const sums = {};
  Fields.forEach((key) => {
    sums[key] = lib.money.from_cents(
      things
        .map((thing) => {
          const { costs } = thing;
          return lib.money.to_cents(costs[key]);
        })
        .reduce((a, b) => a + b)
    );
  });
  return sums;
};

module.exports = ({
  entry,
  updateAction,
  errors,
  toggles,
  toggleAction,
  alt = false,
  headerForEach = false,
}) => {
  module.exports.displayName = "ExtensionEntry";

  const { costs, placements, id, starts, stops } = entry;
  const alt_class = alt ? " form-alt-row" : "";

  const updater = lib.updaters.wrapping({
    object: entry,
    action: updateAction,
  });
  const date_updater = lib.updaters.wrapping({
    object: entry,
    action: updateAction,
    object_pre_test: (all) => {
      const result = lib.obj.clone(all);
      const { starts, stops } = result;
      const problems = tests.date.check.date_range(starts, stops);
      if (problems === undefined) {
        return true;
      }
      ui.alert(problems);
      return false;
    },
    object_transform: (updated) => {
      return {
        ...updated,
        changed: true,
      };
    },
  });
  const placement_updater = lib.updaters.wrapping({
    object: entry,
    action: updateAction,
    object_transform: (updated) => {
      const result = lib.obj.clone(updated);
      const Fields = [
        "budget",
        "ad_fee",
        "placement_fee",
        "cancellation_fee",
        "other_fee",
        "final_cost",
      ];
      result.costs = sum_costs(result.placements);
      if (!lib.coalesce(result.changed, false)) {
        result.changed = result.placements.some((p) => p.changed);
      }
      return result;
    },
  });
  const deleter = () => {
    if (confirm("Are you sure you want to drop this series?")) {
      updater("deleted")(true);
    }
  };

  const toggler = lib.updaters.make(toggleAction);
  const contract_by_default = placements.length > 1;
  const expand_key = `show-series-${id}`;
  const expanded =
    !contract_by_default || lib.coalesce(toggles[expand_key], false);
  const hide_zero_key = `show-zero-amounts-for-series-${id}`;
  const zeroes_hid = lib.coalesce(toggles[hide_zero_key], contract_by_default);

  return (
    <FieldGroup className={`maxcols-40 flush-left ${alt_class}`}>
      <FieldGroup
        className={(expanded ? "maxcols-40" : "maxcols-34") + " flush-left"}
      >
        <ExtensionSeriesHeader showOnlyIf={headerForEach} expanded={expanded} />
        <PlacementsHeader
          showOnlyIf={headerForEach}
          showNoteColumn={expanded}
        />
      </FieldGroup>
      <FieldGroup
        className={(expanded ? "maxcols-40" : "maxcols-34") + " flush-left"}
      >
        <FieldGroup
          className={
            (expanded ? "maxcols-8" : "maxcols-11") + " flush-top flush-left"
          }
        >
          <FieldGroup className="maxcols-8 flush-left">
            <Field className="maxcols-2">
              <Button
                className="field-entry warning-button tiny-button maxcols-padded-1"
                action={deleter}
                title="Drop Series"
              >
                <b>&times;</b>
              </Button>
            </Field>
            <FieldGroup className="maxcols-6 flush-left">
              <Field className="maxcols-3">
                <DateEntry
                  date={starts}
                  updateAction={date_updater("starts", starts)}
                  error={errors[`series-ext-${entry.id}-starts`]}
                />
              </Field>
              <Field className="maxcols-3">
                <DateEntry
                  value={stops}
                  updateAction={date_updater("stops", stops)}
                  error={errors[`series-ext-${entry.id}-stops`]}
                />
              </Field>
            </FieldGroup>
          </FieldGroup>
          <FieldGroup
            showOnlyIf={contract_by_default}
            className={(expanded ? "maxcols-4" : "maxcols-3") + " flush-right"}
          >
            <Field className="maxcols-3 flush-left">
              <Anchor
                className="field-entry item.link maxcols-padded-3"
                action={toggler(expand_key)}
                actionValue={!expanded}
              >
                Details <AccordionDisplay open={expanded} />
              </Anchor>
            </Field>
            <Field showOnlyIf={expanded} className="maxcols-3 flush-left">
              <Anchor
                className="field-entry item.link maxcols-padded-3"
                action={toggler(hide_zero_key)}
                actionValue={!zeroes_hid}
              >
                Zeroes <AccordionDisplay open={!zeroes_hid} />
              </Anchor>
            </Field>
          </FieldGroup>
        </FieldGroup>

        <PlacementsList
          className="maxcols-32 flush-right"
          showOnlyIf={expanded}
          placements={placements}
          showZeroPlacements={!zeroes_hid}
          updateAction={placement_updater("placements")}
        />

        <CostTotals
          showOnlyIf={headerForEach || !expanded}
          showNoteColumn={expanded}
          costs={costs}
        />
      </FieldGroup>
    </FieldGroup>
  );
};
