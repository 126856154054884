const Dump = require("./../Dump.jsx");
const Field = require("./../Field.jsx");
const Label = require("./../Label.jsx");
const Entry = require("./../Entry.jsx");

const OfferTree = ({
  all,
  offers,
  offer,
  addOffer,
  dropOffer,
  labelText = "",
  single = false,
  cols = 24,
}) => {
  OfferTree.displayName = "OfferTree";

  const selected_offers = single
    ? offer !== undefined
      ? [offer]
      : []
    : offers ?? [];

  if ((all ?? []).length == 0) {
    return null;
  }

  return (
    <ul className="list" style={{ width: "auto" }}>
      {all.map((this_offer) => {
        return (
          <li className="item">
            {this_offer.id === undefined ? (
              <details open>
                <summary>
                  <b>{this_offer.name}</b>
                </summary>
                <OfferTree
                  all={this_offer.children}
                  offer={offer}
                  offers={offers}
                  addOffer={addOffer}
                  dropOffer={dropOffer}
                  single={single}
                  cols={cols - 4}
                />
              </details>
            ) : (
              <OfferPick
                offer={this_offer}
                offers={selected_offers}
                addOffer={addOffer}
                dropOffer={dropOffer}
                single={single}
              />
            )}
          </li>
        );
      })}
    </ul>
  );
};

const OfferPick = ({ offer, offers, addOffer, dropOffer, single }) => {
  OfferPick.displayName = "OfferPick";

  const ids = (offers ?? []).map((x) => x.id);
  const checked = (ids ?? []).indexOf(offer.id) > -1;

  const key = `offer-pick-${offer.id}`;

  return (
    <div className="field.multi maxcols-12">
      <input
        id={key}
        name={key}
        type={single ? "radio" : "checkbox"}
        className="maxcols-1"
        style={{
          lineHeight: "32px",
          height: "16px",
          marginTop: "8px",
        }}
        checked={checked}
        onClick={(e) => {
          //e.preventDefault();
          if (checked) {
            dropOffer(offer);
          } else {
            addOffer(offer);
          }
        }}
      />
      <label
        htmlFor={key}
        data-checked={checked}
        className="field.label maxcols-padded-11"
        style={{ width: "auto !important", maxWidth: "100%" }}
      >
        {offer.name}
      </label>
    </div>
  );
};

module.exports = OfferTree;
