const TextInput = require("./TextInput.jsx");

module.exports = ({
    id,
    date,
    timestamp,
    value,
    updateAction,
    className,
    placeholder,
    autoFocus,
    onBlur,
    onFocus,
    onChange,
    tabIndex,
    error,
    warning,
    disabled
}) => {
    module.exports.displayName = "DateAndTimeEntry";

    const classes = lib.handle_classes(
        lib.handle_classes(className, "numeric"),
        "maxcols-padded-3"
    );
    const {to_stamp, from_stamp} = lib.time;
    const the_date = lib.coalesce(date, timestamp, value);
    const blank_date = the_date === undefined || the_date === null;
    const nice_date = blank_date ? "" : lib.time.util.stamp_to_str(the_date);

    const updater = value => {
        if (tests.date.blank(value)) {
            if (timestamp !== undefined) {
                updateAction(undefined);
            }
            return;
        }
        if (!tests.date.valid(value)) {
            return;
        }
        const time =
            the_date === undefined
                ? lib.date.parse(value)
                : lib.time.obj.save(from_stamp(the_date));
        const result = lib.date.parse(value);
        if (result === undefined) {
            updateAction(undefined);
            return;
        }
        if (time !== undefined) {
            lib.time.obj.apply(result, time);
        }
        updateAction(to_stamp(result));
    };

    return (
        <TextInput
            id={id}
            className={classes}
            placeholder={placeholder}
            tabIndex={tabIndex}
            autoFocus={autoFocus}
            value={nice_date}
            updateAction={updater}
            mask={standard.masks.date}
            updateOn="blur"
            onFocus={onFocus}
            error={error}
            warning={warning}
            disabled={disabled}
        />
    );
};
