import { money } from "./numbers.js";

const AddressInfo = () => {
  return {
    change: "revise",
    current: AddressEntry(),
    history: [],
  };
};

const AddressEntry = () => {
  return {
    company: "",
    street: "",
    city: "",
    state: "",
    zip: "",
    blank: true,
  };
};

const ContactEntry = () => {
  return {
    email: "",
    fax: "",
    id: "",
    mobile_phone: "",
    other: {
      name: "",
      number: "",
    },
    pager: "",
    work_phone: "",
    blank: true,
  };
};

const ContactInfo = () => {
  return {
    change: "revise",
    current: ContactEntry(),
    history: [],
  };
};

const PersonInfo = () => {
  return {
    contact: ContactInfo(),
    demographics: {},
    first_name: "",
    middle_name: "",
    last_name: "",
    id: "",
    primary: false,
    droppable: true,
  };
};

const name_employee = (e) => {
  if (e.id === undefined) {
    return undefined;
  }
  const name = `${e.last_name}, ${e.first_name}`;
  if (name === ", ") {
    return `(Unknown #${e.id})`;
  }
  return name;
};

const ExtensionSeries = () => {
  return {
    id: "new",
    budgeted: false,
    costs: SeriesCosts(),
    placements: [],
  };
};

const SeriesCosts = () => {
  return {
    budget: money.to_obj("0"),
    ad_fee: money.to_obj("0"),
    placement_fee: money.to_obj("0"),
    cancellation_fee: money.to_obj("0"),
    other_fee: money.to_obj("0"),
    final_cost: money.to_obj("0"),
  };
};

module.exports = {
  AddressEntry,
  AddressInfo,
  ContactEntry,
  ContactInfo,
  ExtensionSeries,
  PersonInfo,
  SeriesCosts,
  name_employee,
};
