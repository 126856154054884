const Entry = (props) => {
  if (props.showOnlyIf !== undefined) {
    if (!props.showOnlyIf) {
      return <span />;
    }
  }
  const classes = lib.handle_classes(props.className, "field.entry");

  return <div className={classes}>{props.children}</div>;
};

Entry.displayName = "Entry";
module.exports = Entry;
