const Field = ({ className, children, style, showOnlyIf = true }) => {
  if (!showOnlyIf) {
    return <span />;
  }
  const classes = lib.handle_classes(className, "field");

  return (
    <div className={classes} style={style}>
      {children}
    </div>
  );
};

Field.displayName = "Field";
module.exports = Field;
