const Anchor = require("./../Anchor.jsx");

module.exports = ({
	source,
	link,
	text,
	inline,
	className
}) => {
	module.exports.displayName = "SourceMention";
	const id = source.id.toString();
	const use_text = lib.coalesce(text, id);
	if (link) {
		const classes = lib.handle_classes(className, "item.link");
		return (
			<Anchor className={classes}
					href={`${standard.URL.sources.pages}?item=${source.id}`}>
				{use_text}
			</Anchor>
		);
	}
	if (inline) {
		return <span className={className}>{use_text}</span>;
	}
	return <div className={className}>{use_text}</div>;
}
