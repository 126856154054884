/* Data and update management for Fluxxor */

import * as basic from "./basic.js";

const options = {
  as: (params) => {
    const obj = params.obj || {};
    const value_key = params.value_key || "id";
    const display_key = params.display_key || "name";
    const using = JSON.parse(JSON.stringify(obj));
    return {
      value: using[value_key],
      display: using[display_key],
      actual: using,
    };
  },
  build_list: (some_list) => {
    return (some_list ?? []).map((item) => options.as({ obj: item }));
  },
  from_plain: (items) => {
    return items.map((item) => {
      return { value: item, display: item, actual: item };
    });
  },
  as_boolean: (falseLabel = "No", trueLabel = "Yes") => {
    return [
      { value: false, display: falseLabel, actual: false },
      { value: true, display: trueLabel, actual: true },
    ];
  },
  all_states: () => {
    const States = [
      ["Alabama", "AL"],
      ["Alaska", "AK"],
      ["Arizona", "AZ"],
      ["Arkansas", "AR"],
      ["California", "CA"],
      ["Colorado", "CO"],
      ["Connecticut", "CT"],
      ["Delaware", "DE"],
      ["Florida", "FL"],
      ["Georgia", "GA"],
      ["Hawaii", "HI"],
      ["Idaho", "ID"],
      ["Illinois", "IL"],
      ["Indiana", "IN"],
      ["Iowa", "IA"],
      ["Kansas", "KS"],
      ["Kentucky", "KY"],
      ["Louisiana", "LA"],
      ["Maine", "ME"],
      ["Maryland", "MD"],
      ["Massachusetts", "MA"],
      ["Michigan", "MI"],
      ["Minnesota", "MN"],
      ["Mississippi", "MS"],
      ["Missouri", "MO"],
      ["Montana", "MT"],
      ["Nebraska", "NE"],
      ["Nevada", "NV"],
      ["New Hampshire", "NH"],
      ["New Jersey", "NJ"],
      ["New Mexico", "NM"],
      ["New York", "NY"],
      ["North Carolina", "NC"],
      ["North Dakota", "ND"],
      ["Ohio", "OH"],
      ["Oklahoma", "OK"],
      ["Oregon", "OR"],
      ["Pennsylvania", "PA"],
      ["Rhode Island", "RI"],
      ["South Carolina", "SC"],
      ["South Dakota", "SD"],
      ["Tennessee", "TN"],
      ["Texas", "TX"],
      ["Utah", "UT"],
      ["Vermont", "VT"],
      ["Virginia", "VA"],
      ["Washington", "WA"],
      ["Washington, D.C.", "DC"],
      ["West Virginia", "WV"],
      ["Wisconsin", "WI"],
      ["Wyoming", "WY"],
    ];
    return States.map((item) => {
      return { display: item[0], value: item[1] };
    });
  },
  indicate_multiple: (options, label = "(Please select)") => {
    return [{ value: "", display: label, actual: {} }, ...options];
  },
  build_list_multi: (
    some_list,
    blank_text = "(Please select)",
    force_unknown = true
  ) => {
    const list = some_list ?? [];
    if (list.length === 0 && !force_unknown) {
      return [];
    }
    return options.indicate_multiple(options.build_list(list), blank_text);
  },
  build_list_multi_other: (
    some_list,
    blank_text = "(Please select)",
    other_text = "(Other)"
  ) => {
    const result = options.build_list_multi(some_list, blank_text, true);
    return [...result, { value: -1, display: other_text, actual: { id: -1 } }];
  },
};

const handlers = {
  change: (e, props) => {
    if (props.onChange !== undefined) {
      props.onChange(e);
      return;
    }
    const updater = props.updater || props.updateAction;
    if (updater !== undefined) {
      const value = e.target.value;
      updater(value);
      return;
    }
  },
};

const updaters = {
  make: (
    action,
    func = (v) => {
      return v;
    }
  ) => {
    return (field) => {
      return (value) => {
        action(field, func(value));
      };
    };
  },
  wrapping: ({
    object,
    action,
    debug = false,
    pre_test = (x) => true,
    field_pre_tests = {},
    transform = (x) => x,
    field_transforms = {},
    post_test = (x) => true,
    field_post_tests = {},
    object_pre_test = (x) => true,
    object_transform = (x) => x,
    object_post_test = (x) => true,
  }) => {
    return (field, original_value = undefined) => {
      return (value) => {
        if (original_value !== undefined) {
          if (value === original_value) {
            return;
          }
        }
        if (debug) console.log({ field, value });
        if (!pre_test(value)) {
          if (debug) console.log("pretest fail");
          return;
        }
        const field_pretest = field_pre_tests[field];
        if (field_pretest && !field_pretest(value)) {
          if (debug) console.log("pretest fail for " + field);
          return;
        }

        const result = basic.obj.clone(object);
        const final_value =
          field_transforms[field] === undefined
            ? transform(value)
            : field_transforms[field](transform(value));

        if (!post_test(final_value)) {
          if (debug) console.log("posttest fail");
          return;
        }

        const field_posttest = field_post_tests[field];
        if (field_posttest && !field_posttest(final_value)) {
          if (debug) console.log("posttest fail for " + field);
          return;
        }

        if (typeof field === "object") {
          basic.obj.path_set(result, field, final_value);
        } else {
          result[field] = final_value;
        }
        if (!object_pre_test(result)) {
          if (debug) console.log("object pretest fail");
          return;
        }
        const final_result = object_transform(result);
        if (!object_post_test(final_result)) {
          if (debug) console.log("object posttest fail");
          return;
        }

        action(final_result);
      };
    };
  },
  nesting: (action, steps = []) => {
    return {
      act: (...args) => {
        action(steps, ...args);
      },
      nest: (...further) => {
        const combined = [...steps, ...further];
        return updaters.nesting(action, combined);
      },
    };
  },
};

const setup_make_updater = (func) => {
  return (action) => {
    return updaters.make(action, func);
  };
};

import { money, numbers } from "./numbers.js";

updaters.make_money_obj = setup_make_updater(money.from_str);
updaters.make_percent = setup_make_updater(numbers.as_percent);

module.exports = {
  options,
  updaters,
};
