const Anchor = require("./../Anchor.jsx");

module.exports = ({
	announcement,
	link,
	inline,
	displayStyle,
	className
}) => {
	module.exports.displayName = "AnnouncementMention";

	if (link) {
		const classes = lib.handle_classes(className, "item.link");
		return (
			<Anchor className={classes}
					href={`${standard.URL.announcements.pages}?item=${announcement.id}`}>
				<q>{announcement.subject}</q>
			</Anchor>
		);
	}
	if (inline) {
		return <span className={className}><q>{announcement.subject}</q></span>;
	}
	return <div className={className}><q>{announcement.subject}</q></div>;
}
