const React = require("react");

const Accordion = require("./../Accordion.jsx");
const AccordionDisplay = require("./../AccordionDisplay.jsx");
const Anchor = require("./../Anchor.jsx");
const Dump = require("./../Dump.jsx");
const EditTrigger = require("./../EditTrigger.jsx");
const Entry = require("./../Entry.jsx");
const Field = require("./../Field.jsx");
const FieldGroup = require("./../FieldGroup.jsx");
const Label = require("./../Label.jsx");
const Select = require("./../Select.jsx");
const TextInput = require("./../TextInput.jsx");
const ViewField = require("./../ViewField.jsx");

const ContactDisplay = require("./ContactDisplay.jsx");
const ContactEditor = require("./ContactEditor.jsx");
const ContactHistoryDisplay = require("./ContactHistoryDisplay.jsx");

const Options = [
    {value: "revise", display: "Edit Current Contact"},
    {value: "add", display: "Change Contact and Archive Old"}
];
const null_option = {value: "none", display: "(select to modify)"};

const Display = ({contact, editAction, fieldClasses}) => {
    const handleEdit = lib.comp.handle_if_extant(editAction);

    return (
        <div className="contact-editor-field">
            <EditTrigger onClick={handleEdit}>
                <ContactDisplay
                    contact={contact.current}
                    fieldClasses={fieldClasses}
                />
            </EditTrigger>
        </div>
    );
};

const Edit = ({contact, addOnly, changeOptions, updateAction}) => {
    const updater = lib.updaters.wrapping({
        object: contact,
        action: updateAction,
        object_transform: info => {
            const result = lib.obj.clone(info);
            if (result.change === "none") {
                result.change = "revise";
            }
            return result;
        }
    });

    return (
        <div>
            <ContactEditor
                contact={contact.current}
                updateAction={updater("current")}
            />
            {addOnly ? (
                <span />
            ) : (
                <div>
                    <br />
                    <Select
                        name="change"
                        className="maxcols-padded-8"
                        value={contact.change}
                        options={changeOptions}
                        updateAction={updater("change")}
                    />
                    <br />
                </div>
            )}
        </div>
    );
};

const History = ({history, show, toggleShow}) => {
    if (history.length === 0) {
        return <span />;
    }

    return (
        <div>
            <div className="item">
                <Anchor className="menu-item.link to-left" action={toggleShow}>
                    History <AccordionDisplay open={show} />
                </Anchor>
            </div>
            <Accordion open={show}>
                <ContactHistoryDisplay history={history} />
            </Accordion>
        </div>
    );
};

module.exports = React.createClass({
    displayName: "ContactForm",
    changeOptions: Options,

    getInitialState: function() {
        return this.props;
    },
    componentWillReceiveProps: function(newProps) {
        this.setState(newProps);
    },

    toggleHistory: function() {
        this.setState({show_history: !this.state.show_history});
    },

    render: function() {
        const {
            edit,
            addOnly,
            label,
            hideLabel,
            className,
            fieldClassName,
            labelClassName,
            entryClassName,
            editAction,
            updateAction,
            showOnlyIf
        } = this.props;
        if (!lib.coalesce(showOnlyIf, true)) {
            return <span />;
        }

        const {contact, show_history} = this.state;
        const fieldClasses = lib.handle_classes(
            fieldClassName,
            "contact-editor-field"
        );
        const labelClasses = lib.handle_classes(labelClassName, "field.label");
        const entryClasses = lib.handle_classes(entryClassName, "field.multi");

        return (
            <FieldGroup className={className}>
                <Field className={fieldClasses}>
                    <Label
                        showOnlyIf={!hideLabel}
                        text={label || "Contact:"}
                        className={labelClasses}
                    />
                    <Entry className={entryClasses}>
                        <div>
                            <div>
                                {edit ? (
                                    <Edit
                                        contact={contact}
                                        addOnly={addOnly}
                                        changeOptions={this.changeOptions}
                                        updateAction={updateAction}
                                    />
                                ) : (
                                    <Display
                                        contact={contact}
                                        editAction={editAction}
                                        fieldClasses={fieldClasses}
                                    />
                                )}
                            </div>
                            <History
                                history={contact.history}
                                show={show_history}
                                toggleShow={this.toggleHistory}
                            />
                        </div>
                    </Entry>
                </Field>
            </FieldGroup>
        );
    }
});
