const Accordion = require("./../Accordion.jsx");
const AccordionDisplay = require("./../AccordionDisplay.jsx");
const Entry = require("./../Entry.jsx");
const Field = require("./../Field.jsx");
const FieldGroup = require("./../FieldGroup.jsx");
const Label = require("./../Label.jsx");
const NotesList = require("./NotesList.jsx");
const NotesDisplay = require("./NotesDisplay.jsx");
const TextInput = require("./../TextInput.jsx");


module.exports = ({
    location,             // location name ("Ads", "Contacts", etc.) for type of item
    item,
    className,
    show,
    toggleShow,
    notes=[],
    newNote="",
    updateAction
}) => {
    module.exports.displayName = "NotesForm";
    const classes = lib.handle_classes(
        className, "notes-form"
    );
    const col_classes = classes.split(" ").filter(
        c => c.indexOf("maxcol") > -1
    );
    const accordion_class = (
        col_classes.length > 0
        ?
        col_classes[0] + " middle"
        :
        "middle"
    );
    const handleToggle = (e) => {
        e.preventDefault();
        toggleShow();
    };
    const {pluralize, int_format} = lib.numbers;

    return (
        <FieldGroup className={classes}>
            <NoteEditor newNote={newNote} className={className}
                        location={location} item={item}
                        updateAction={updateAction} />
			<NotesDisplay notes={notes} className={className}
						  show={show} toggleShow={toggleShow} />
        </FieldGroup>
    );
}




const NoteEditor = ({newNote, updateAction, className}) => {
    const classes = lib.handle_classes(
        className, "note-editor"
    );
    return (
        <Field className={classes}>
            <Label text="Add a Note:" />
            <TextInput name="new_note" area={true}
                       className="field.entry maxcols-12"
                       value={newNote}
                       updater={updateAction} />
        </Field>
    );
};
