const empty_hash = () => {
  const keys = Object.keys(hash_to_json());
  return keys.length === 0;
};

const empty_query = () => {
  const keys = Object.keys(query_string_to_json());
  return keys.length === 0;
};

const handle_classes = (provided, required) => {
  let result = (provided || required).toString();
  if (result.indexOf(required) == -1) {
    result = required + " " + result;
  }
  return result;
};

const hash_to_json = () => {
  // from http://www.developerdrive.com/2013/08/turning-the-querystring-into-a-json-object-using-javascript/
  if (location.hash.length < 2) {
    return {};
  }

  const pairs = location.hash.slice(1).split("&");
  const result = {};
  pairs.forEach(function (pair) {
    pair = pair.split("=");
    if (pair[0] !== "") {
      result[pair[0]] = decodeURIComponent(pair[1] || "");
    }
  });

  return JSON.parse(JSON.stringify(result));
};

const its_me = () => {
  // For minor client debugging and testing
  const { name, role } = window.user_info;
  return name === "Eric Thompson" && role === "Super User";
};

const query_params = () => {
  const raw = new URLSearchParams(document.location.search);
  const result = {};
  for (const [key, value] of raw.entries()) {
    result[key] = raw.getAll(key);
    if (result[key].length === 1) {
      result[key] = result[key][0];
    }
  }
  return result;
};

const query_string_to_json = () => {
  // from http://www.developerdrive.com/2013/08/turning-the-querystring-into-a-json-object-using-javascript/
  const pairs = location.search.slice(1).split("&");

  const result = {};
  pairs.forEach(function (pair) {
    pair = pair.split("=");
    if (pair[0] !== "") {
      result[pair[0]] = decodeURIComponent(pair[1] || "");
    }
  });

  return JSON.parse(JSON.stringify(result));
};

const ready = (func) => {
  window.addEventListener("load", func);
};

module.exports = {
  empty_hash,
  empty_query,
  handle_classes,
  hash_to_json,
  its_me,
  query_params,
  query_string_to_json,
  ready,
};
