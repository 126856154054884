var React = require("react");

var ItemEditingControls = require("./ItemEditingControls.jsx");

module.exports = React.createClass({
    displayName: "GroupedItem",
    name_field: "name",
    key_field: "id",

    getInitialState: function() {
        var initial = JSON.parse(JSON.stringify(this.props));
        initial["original"] = JSON.parse(JSON.stringify(this.props.item));
        //initial["savable"] = this.checkSavable(this.props.item[this.name_field]);
        if (initial.nameField !== undefined) {
            this.name_field = initial.nameField;
        }
        if (initial.keyField !== undefined) {
            this.key_field = initial.keyField;
        }
        return initial;
    },

    componentWillReceiveProps: function(newProps) {
        this.setState(newProps);
        this.setState({
            original: JSON.parse(JSON.stringify(newProps.item)) //,
            //"savable":this.checkSavable(newProps.item[this.name_field])
        });
    },

    handleEdit: function(e) {
        e.preventDefault();
        var item = this.state.item;
        item[this.name_field] = this.state.original[this.name_field];
        this.props.editAction(this.props.index);
        if (item[this.name_field] == "" && item.droppable) {
            if (this.props.dropAction !== undefined) {
                this.props.dropAction(this.props.index);
            }
        }
    },
    handleChange: function(e) {
        var item = lib.obj.clone(this.state.item);
        if (this.props.options === undefined) {
            item[this.name_field] = e.target.value.toString().trim();
        } else {
            var item = Object.assign(item, e);
        }
        this.setState({item: item});
    },
    handleUpdate: function(e) {
        e.preventDefault();
        const item = this.state.item;
        delete item["is_new"];
        const save_after = e.currentTarget.localName === "form";
        if (this.props.addAction !== undefined) {
            this.props.addAction(item, save_after);
        } else if (this.props.updateAction !== undefined) {
            this.props.updateAction(this.props.index, item, save_after);
        }
    },
    handleDelete: function(e) {
        e.preventDefault();
        this.props.dropAction(this.props.index);
    },

    editingMode: function() {
        var item = this.state.item;
        var default_value =
            this.props.options === undefined
                ? item[this.name_field]
                : item[this.key_field];
        return (
            <div className="to-right">
                <ItemEditingControls
                    defaultValue={default_value}
                    input={this.props.input}
                    canChangeIfNotDroppable={this.props.canChangeIfNotDroppable}
                    options={this.props.options}
                    renameAction={this.handleChange}
                    onDone={this.handleUpdate}
                    onCancel={this.handleEdit}
                    onDrop={this.handleDelete}
                    inputClass={this.props.inputClass}
                    disabled={item[this.name_field] == ""}
                    canDelete={item.droppable && !item.is_new}
                />
            </div>
        );
    },

    render: function() {
        const {item} = this.state;
        const {index} = this.props;
        const can_change_if_not_drop = lib.coalesce(
            this.props.canChangeIfNotDroppable,
            true
        );
        if (item.editing) {
            return this.editingMode();
        }
        const display =
            item.droppable || can_change_if_not_drop ? (
                <a className="item.link" onClick={this.handleEdit} tabIndex="0">
                    {item[this.name_field]}
                </a>
            ) : (
                <span className="item.stub" tabIndex="0">
                    {item[this.name_field]}
                </span>
            );
        return (
            <div>
                {display}
                {this.props.subList === undefined
                    ? null
                    : this.props.subList.map(sub => {
                          const key = this.props.id + "-" + index.toString();
                          return <div key={key}>{sub(item, index)}</div>;
                      })}
            </div>
        );
    }
});
