const React = require("react");

const Dump = require("./Dump.jsx");
const Field = require("./Field.jsx");
const Label = require("./Label.jsx");
const GroupedItem = require("./GroupedItem.jsx");

module.exports = React.createClass({
  displayName: "GroupedItemList",

  getInitialState: function () {
    const state = lib.obj.clone(this.props);

    this.name_field = lib.coalesce(state.nameField, "name");
    this.key_field = lib.coalesce(state.keyField, "id");

    state.new_item = undefined;
    state.original = lib.obj.clone(this.props);

    return state;
  },

  componentWillReceiveProps: function (newProps) {
    this.setState(lib.obj.clone(newProps));
    this.setState({ original: lib.obj.clone(newProps) });
  },

  availableOptionsFor(options, items, item) {
    const unique_field = this.props.uniqueField;
    if (unique_field === undefined || options === undefined) {
      return undefined;
    }
    const used = items
      .filter((item) => {
        return !item.deleted;
      })
      .map((item) => {
        return item[unique_field];
      });
    return options.filter((option) => {
      return (
        (item !== undefined && item[unique_field] == option.value) ||
        used.indexOf(option.value) == -1
      );
    });
  },

  handleNew: function (e) {
    e.preventDefault();
    this.setState({
      new_item: Object.assign(
        { droppable: true, is_new: true, editing: true, deleted: false },
        this.props.addSetup.blank
      ),
    });
  },
  handleCloseAddItem: function () {
    this.setState({ new_item: undefined });
  },
  handleAdd: function (item, save_after = false) {
    const new_item = lib.obj.clone(item);
    new_item.editing = false;
    this.setState({ new_item: undefined });
    this.props.addAction(new_item, save_after);
  },

  renderItems: function (items, any_editing) {
    return items.map((item, i) => {
      if (item.deleted) {
        return <span />;
      }
      const key = this.props.id + "-items-" + i.toString();
      const editToggler =
        any_editing && !item.editing
          ? (e) => {
              e.preventDefault();
            }
          : this.props.editAction;
      const available_options = this.availableOptionsFor(
        this.props.options,
        items,
        item
      );
      return (
        <li className="item" key={key}>
          <GroupedItem
            item={item}
            index={i}
            id={key}
            input={this.props.input}
            options={available_options}
            nameField={this.props.nameField}
            keyField={this.props.keyField}
            inputClass={this.props.inputClass}
            editAction={editToggler}
            canChangeIfNotDroppable={this.props.canChangeIfNotDroppable}
            updateAction={this.props.updateAction}
            dropAction={this.props.dropAction}
            subList={this.props.subList}
          />
        </li>
      );
    });
  },
  renderAddButton: function (any_editing) {
    if (any_editing || this.props.addSetup === undefined) {
      return <span />;
    }
    const label =
      this.props.addSetup !== undefined
        ? this.props.addSetup.label || "Add"
        : "Add";
    if (this.props.addSetup.options !== undefined) {
      const available_options = this.availableOptionsFor(
        this.props.addSetup.options,
        this.props.items,
        this.state.new_item
      );
      const no_options = !available_options.some((opt) => {
        return opt.actual[this.key_field] !== undefined;
      });
      if (no_options) {
        return <span />;
      }
    }
    return (
      <li className="item">
        <div className="stubbed-area">
          <button
            className="little-button"
            disabled={this.props.disabled}
            onClick={this.handleNew}
          >
            {label}
          </button>
        </div>
      </li>
    );
  },
  renderNewItem: function () {
    const available_options = this.availableOptionsFor(
      this.props.addSetup.options,
      this.props.items,
      this.state.new_item
    );
    const key = this.props.id + "-items-new";
    return (
      <li className="item" key={key}>
        <GroupedItem
          item={this.state.new_item}
          key={key}
          inputClass={this.props.inputClass}
          input={this.props.input}
          options={available_options}
          nameField={this.props.nameField}
          keyField={this.props.keyField}
          editAction={this.handleCloseAddItem}
          addAction={this.handleAdd}
        />
      </li>
    );
  },

  render: function () {
    if (!this.state.items) {
      return <span />;
    }
    const any_editing = this.state.items.some((item, i, ray) => {
      return item.editing;
    });
    return (
      <Field className={this.props.className}>
        {this.state.text || "" !== "" ? (
          <Label text={this.state.text} className={this.props.labelClass} />
        ) : null}
        <div className="field.multi">
          <ul className="list flush-both fill-space">
            {this.renderItems(this.state.items, any_editing)}
            {this.state.new_item === undefined
              ? this.renderAddButton(any_editing)
              : this.renderNewItem()}
          </ul>
        </div>
      </Field>
    );
  },
});
