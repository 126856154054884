const FieldGroup = require("./FieldGroup.jsx");
const Dump = require("./Dump.jsx");

module.exports = ({
  changed,
  submitForm,
  resetForm,
  deleteItem,
  changedText = "Save Changes",
  unchangedText = "No Changes",
  cancelText = "Cancel Edits",
  deleteText = "Delete This",
  columns = 20,
  style = {},
  showOnlyIf = true,
  alwaysAllowCancel = false,
}) => {
  module.exports.displayName = "SaveAndCancelControls";
  if (!showOnlyIf) {
    return <span />;
  }

  const classes = `controls-fieldgroup maxcols-${columns}`;

  const SaveCancel = changed ? (
    <FieldGroup className={classes} style={style}>
      <button className="submit-button primary-button" onClick={submitForm}>
        {changedText}
      </button>
      <button className="reset-button" onClick={resetForm}>
        {cancelText}
      </button>
    </FieldGroup>
  ) : (
    <FieldGroup className={classes} style={style}>
      <button className="submit-button" disabled>
        {unchangedText}
      </button>
      {alwaysAllowCancel ? (
        <button className="reset-button" onClick={resetForm}>
          {cancelText}
        </button>
      ) : (
        <button className="reset-button" disabled>
          {cancelText}
        </button>
      )}
    </FieldGroup>
  );
  const Delete =
    deleteItem === undefined ? (
      <span />
    ) : (
      <div>
        <br /> <br />
        <FieldGroup className="controls-fieldgroup maxcols-4">
          <button className="warning-button maxcols-4" onClick={deleteItem}>
            {deleteText}
          </button>
        </FieldGroup>
      </div>
    );

  return (
    <div>
      {SaveCancel}
      {Delete}
    </div>
  );
};
