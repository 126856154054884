const TerritoryOutlineMarket = require("./TerritoryOutlineMarket.jsx");

module.exports = (props) => {
	module.exports.displayName = "TerritoryOutlineState";
	
    return (
        <div>
            <span className="item.stub">
                {(props.name || (<i>no states set</i>))}
            </span>
            <ul className="list">
                {
                    Object.keys(props.info).map((market_id, i) => {
                        var market = props.structure[market_id];
                        var key = "list-markets-" + market.id.toString();
                        return (
                            <li className="item" key={key}>
                                <TerritoryOutlineMarket
                                    id={market.id} name={market.name}
                                    info={props.info[market_id]}
                                    structure={market.projects} />
                            </li>
                        );
                    })
                }
            </ul>
        </div>
    );
}
