module.exports = ({className, children, showOnlyIf = true}) => {
    module.exports.displayName = "FieldGroup";

    if (!showOnlyIf) {
        return <span />;
    }
    const classes = lib.handle_classes(className, "fieldgroup");

    return <div className={classes}>{children}</div>;
};
