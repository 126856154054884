module.exports = ({
  action,
  actionValue,
  onClick,
  children,
  className,
  title,
  style,
  disabled,
  href = "#",
  tabIndex = 0,
  showOnlyIf = true,
}) => {
  module.exports.displayName = "Button";
  if (!showOnlyIf) {
    return <span />;
  }
  const handleClick = (e) => {
    if (onClick !== undefined) {
      onClick(e);
      return;
    }
    if (action !== undefined) {
      e.preventDefault();
      if (actionValue !== undefined) {
        action(actionValue);
      } else {
        action();
      }
      return;
    }
  };

  return (
    <button
      className={className}
      style={style}
      onClick={handleClick}
      href={href}
      tabIndex={tabIndex}
      title={title}
      disabled={disabled}
    >
      {children}
    </button>
  );
};
