const Select = require("./Select.jsx");

module.exports = props => {
    module.exports.displayName = "SmallGroupedItemSelector";
    var real_options = props.options.filter(opt => {
        return (opt.value || "") != "";
    });

    var handleUpdate = items => {
        var id_field = props.valueField || "id";
        var get_ids = ray => {
            return ray.map(item => {
                return item[id_field];
            });
        };
        //var in_props = get_ids(props.items)
        var in_items = get_ids(items);
        var kept = items.map(item => {
            item.deleted = false;
            return item;
        });
        var deleted = props.items
            .filter(item => {
                return in_items.indexOf(item[id_field]) == -1;
            })
            .map(item => {
                item.deleted = true;
                return item;
            });
        var result = [...kept, ...deleted];
        props.updateAction(result);
    };
    var active_items = props.items.filter(item => {
        return !item.deleted;
    });
    return (
        <Select
            id={props.id}
            className={props.className}
            options={real_options}
            multiple={true}
            size={real_options.length}
            valueField={props.valueField || "id"}
            value={active_items}
            updateAction={handleUpdate}
        />
    );
};
