const React = require("react");

module.exports = React.createClass({
    displayName: "Accordion",
    render: function() {
        const {open, large, className, id} = this.props;
        const the_class = large ? "large-accordion" : "accordion";
        const classes = lib.handle_classes(className, the_class);
        const open_class = open ? "is-open" : "";

        return (
            <div className={`${classes} ${open_class}`} aria-expanded={open} id={id}>
                {React.Children.map(this.props.children, child => {
                    return child;
                })}
            </div>
        );
    }
});
