const Dump = require("./../Dump.jsx");
const Time = require("./../Time.jsx");

const EmployeeMention = require("./EmployeeMention.jsx");

const build_history_structure = details => {
    const result = {};
    let names = {};
    let pos = {};
    let part = "";

    const default_it = (obj, key, value) => {
        if (obj[key] === undefined) {
            obj[key] = value;
        }
        return obj[key];
    };
    const name = item => item.name.value;
    const place = item => {
        return lib.coalesce(item.id, item.abbr).changed;
    };
    for (let deet of details) {
        const {
            company,
            state,
            market,
            project,
            all_states_under,
            all_markets_under,
            all_projects_under,
            start_date,
            stop_date
        } = deet;
        company.states = {};
        company.all_states_under = all_states_under;
        pos = default_it(result, name(company), company);
        state.markets = {};
        state.all_markets_under = all_markets_under;
        pos = default_it(pos.states, name(state), state);
        market.projects = {};
        market.all_projects_under = all_projects_under;
        pos = default_it(pos.markets, name(market), market);
        pos = default_it(pos.projects, name(project), project);
        pos.start_date = start_date;
        pos.stop_date = stop_date;
    }
    return result;
};

const Setups = [
    //{all:"all_companies", children:"companies"},
    {all: "all_states_under", children: "states"},
    {all: "all_markets_under", children: "markets"},
    {all: "all_projects_under", children: "projects"},
    {use_projects: true}
];

const ListDetails = ({details, setups, parentKey}) => {
    const items = lib.obj.values(details);
    if (items.length === 0) {
        return <span />;
    }
    const key_from_item = (parent_key, item) => {
        const id_code = lib.coalesce(item.id, item.abbr).value;
        return `${parent_key}-${id_code}`;
    };
    const display_item = (item, setups, parent_key) => {
        return lib.coalesce(setups[0].use_projects, false) ? (
            <ProjectDetail item={item} />
        ) : (
            <Detail
                item={item}
                parentKey={key_from_item(parent_key, item)}
                setups={setups}
            />
        );
    };

    if (items.length === 1) {
        const item_key = key_from_item(parentKey, items[0]);
        return <span>{display_item(items[0], setups, item_key)}</span>;
    }

    const sorted_items = items.sort((a, b) =>
        lib.cmp(a.name.value, b.name.value)
    );
    return (
        <ul className="list">
            {sorted_items.map(item => {
                const item_key = key_from_item(parentKey, item);
                return (
                    <li className="item" key={item_key}>
                        {display_item(item, setups, item_key)}
                    </li>
                );
            })}
        </ul>
    );
};

const Detail = ({item, setups, parentKey}) => {
    const children = setups.length > 0 ? item[setups[0].children] : {};
    const all =
        setups.length > 0 ? item[setups[0].all] : {changed: "none", value: ""};
    all.state = setups.length > 0 ? (all.value ? "All" : "sel.") : "";
    const children_same = lib.obj
        .values(children)
        .every(child => child.changed === "none");
    const any_arrow =
        lib.obj.values(children).length === 1 ? <b> &rarr; </b> : <span />;
    const {name} = item;
    const value = lib.coalesce(item.id, item.abbr);

    return (
        <span className="item">
            <span>
                <Represent display={name} value={value} />
                {all.changed == "none" ? (
                    <span />
                ) : (
                    <Represent
                        display={{value: "(" + all.state + ")"}}
                        value={all}
                    />
                )}
                {lib.obj.is_empty(children) ? (
                    <span />
                ) : (
                    <span>
                        {any_arrow}
                        <ListDetails
                            details={children}
                            setups={setups.slice(1)}
                            parentKey={parentKey}
                        />
                    </span>
                )}
            </span>
        </span>
    );
};

const ProjectDetail = ({item}) => {
    const {name, id, start_date, stop_date} = item;
    return (
        <span className="item">
            <Represent display={name} value={id} />
            <DateRange range={{start_date, stop_date}} />
        </span>
    );
};

const DateRange = ({range}) => {
    const {start_date, stop_date} = range;
    const blank = <i>Open</i>;
    const for_start = <Time date={start_date.value} showOnBlank={blank} />;
    const for_stop = <Time date={stop_date.value} showOnBlank={blank} />;
    const any_date_changes =
        start_date.changed !== "none" || stop_date.changed !== "none";
    return any_date_changes ? (
        <ul className="list">
            <li className="item">
                <span className="text">
                    Start:{" "}
                    <Represent
                        display={{value: for_start}}
                        value={start_date}
                    />
                </span>
            </li>
            <li className="item">
                <span className="text">
                    Stop:{" "}
                    <Represent display={{value: for_stop}} value={stop_date} />
                </span>
            </li>
        </ul>
    ) : (
        <span />
    );
};

const Represent = ({display, value}) => {
    const highlight = (name, change) => {
        if (change === "added") {
            return <ins>{name}</ins>;
        }
        if (change === "removed") {
            return <del>{name}</del>;
        }
        if (change === "changed") {
            return <mark>{name}</mark>;
        }
        return <span>{name}</span>;
    };
    return (
        <span className={"text was-change-" + value.changed}>
            {highlight(display.value, value.changed)}
        </span>
    );
};

module.exports = ({id, history}) => {
    module.exports.displayName = "TerritoryHistory";
    if (history === undefined) {
        return <span />;
    }
    const sorted_history = history
        .sort((a, b) => a.recorded - b.recorded)
        .reverse();
    return (
        <ul className="list">
            {sorted_history.map(entry => {
                const {user, recorded, details} = entry;
                const entry_key = `${id}-entry-${recorded}`;
                const history_details = build_history_structure(details);
                return (
                    <li className="item maxcols-22" key={entry_key}>
                        <Time
                            className="header-time text flush-left"
                            timestamp={recorded}
                            formatter={lib.time.format.normal}
                        />
                        <cite>
                            <EmployeeMention
                                className="text flush-left"
                                person={user}
                            />
                        </cite>
                        <div>
                            <ListDetails
                                details={history_details}
                                setups={Setups}
                                parentKey={entry_key}
                            />
                        </div>
                        <br />
                    </li>
                );
            })}
        </ul>
    );
};
