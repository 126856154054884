const React = require("react");

const Anchor = require("./../../Anchor.jsx");
const Dump = require("./../../Dump.jsx");
const FieldGroup = require("./../../FieldGroup.jsx");
const Field = require("./../../Field.jsx");
const Label = require("./../../Label.jsx");
const TextInput = require("./../../TextInput.jsx");

const ExtensionSeriesHeader = require("./ExtensionSeriesHeader.jsx");
const PlacementsHeader = require("./PlacementsHeader.jsx");
const ExtensionEntry = require("./ExtensionEntry.jsx");

module.exports = React.createClass({
  displayName: "ExtensionSeries",

  validateAddNew: function (start_date, stop_date, min_date) {
    const { blank } = tests;
    const { valid, before } = tests.date;
    const ok_date = (dt, name) => {
      if (blank(dt)) {
        ui.alert(`Make sure ${name} is set.`);
        return false;
      }
      if (!valid(dt)) {
        ui.alert(`Make sure ${name} is valid.`);
        return false;
      }
      if (min_date !== undefined) {
        if (!before(min_date, dt)) {
          ui.alert(
            `Make sure ${name} is not before extension start date ${min_date}.`
          );
          return false;
        }
      }
      return true;
    };

    if (!ok_date(start_date, "start date")) {
      return false;
    }
    if (!ok_date(stop_date, "stop date")) {
      return false;
    }
    if (!before(start_date, stop_date)) {
      ui.alert("Make sure stop date is greater than start date.");
      return false;
    }
    return true;
  },

  handleAdd: function (e) {
    e.preventDefault();

    const { from_iso, to_iso, parse } = lib.date;

    const { entries, startDate, territory, updateAction } = this.props;
    const get_ref = (name) => {
      return this.refs[name].state;
    };
    const start_date = get_ref("start_date");
    const stop_date = get_ref("stop_date");
    const dates = {
      starts: to_iso(parse(start_date.value)),
      stops: to_iso(parse(stop_date.value)),
    };
    const valid = this.validateAddNew(dates.starts, dates.stops, startDate);
    if (!valid) {
      return;
    }

    const right_now = new Date().getTime();
    const new_id = `new-${right_now}`;
    const new_series = Object.assign({}, models.ExtensionSeries(), {
      id: new_id,
      created: true,
      starts: dates.starts,
      stops: dates.stops,
    });

    new_series.placements = domain.territories
      .projects_for(territory, new_series.starts, new_series.stops)
      .filter((p) => p.current)
      .map((project, i) => {
        return {
          id: `new-placement-${right_now}-${i}`,
          project: { id: project.id, name: project.name },
          budgeted: false,
          created: true,
          costs: models.SeriesCosts(),
        };
      });

    start_date.value = "";
    stop_date.value = "";

    updateAction([...entries, new_series]);
  },

  render: function () {
    const {
      entries,
      territory,
      updateAction,
      errors,
      toggles,
      toggleAction,
    } = this.props;

    if (entries.length < 0) {
      return <span />;
    }
    const use_top_header =
      entries.length == 0 || entries[0].placements.length < 2;
    const updater = lib.updaters.wrapping({
      object: entries,
      action: updateAction,
    });
    let alt = false;

    return (
      <FieldGroup className="maxcols-40">
        <FieldGroup
          showOnlyIf={use_top_header}
          className="maxcols-40 flush-left"
        >
          <ExtensionSeriesHeader
            showOnlyIf={use_top_header}
            expanded={use_top_header}
          />
          <PlacementsHeader
            showOnlyIf={use_top_header}
            showNoteColumn={use_top_header}
          />
        </FieldGroup>
        {entries.map((entry, i) => {
          const key = `series-ext-${entry.id}`;
          if (entry.deleted) {
            return <span key={key} />;
          }
          alt = !alt;

          return (
            <ExtensionEntry
              entry={entry}
              key={key}
              alt={alt}
              toggles={toggles}
              toggleAction={toggleAction}
              updateAction={updater(i)}
              headerForEach={!use_top_header}
              errors={errors}
            />
          );
        })}

        <br />

        <FieldGroup
          className={"maxcols-40 flush-left " + (!alt ? " form-alt-row" : "")}
        >
          <div className="maxcols-2" />
          <Field className="maxcols-3">
            <TextInput
              className="maxcols-padded-3 to-right"
              ref="start_date"
              placeholder="Starts"
              mask={standard.masks.date}
            />
          </Field>
          <Field className="maxcols-3">
            <TextInput
              className="maxcols-padded-3 to-right"
              ref="stop_date"
              placeholder="Stops"
              mask={standard.masks.date}
            />
          </Field>

          <Field className="maxcols-2">
            <button
              className="field-entry tiny-button maxcols-padded-1 flush-right"
              onClick={this.handleAdd}
              title="Add series"
            >
              <b>+</b>
            </button>
          </Field>

          <br />
        </FieldGroup>
      </FieldGroup>
    );
  },
});
