const components = {
  Accordion: require("./Accordion.jsx"),
  AccordionControl: require("./AccordionControl.jsx"),
  AccordionDisplay: require("./AccordionDisplay.jsx"),
  Anchor: require("./Anchor.jsx"),
  Button: require("./Button.jsx"),
  AttachmentLink: require("./AttachmentLink.jsx"),
  AttachmentLinkList: require("./AttachmentLinkList.jsx"),
  CheckBox: require("./CheckBox.jsx"),
  DateAndTimeEntry: require("./DateAndTimeEntry.jsx"),
  DateEntry: require("./DateEntry.jsx"),
  Dump: require("./Dump.jsx"),
  EditTrigger: require("./EditTrigger.jsx"),
  Entry: require("./Entry.jsx"),
  Field: require("./Field.jsx"),
  FieldGroup: require("./FieldGroup.jsx"),
  FileUpload: require("./FileUpload.jsx"),
  GroupedItem: require("./GroupedItem.jsx"),
  GroupedItemList: require("./GroupedItemList.jsx"),
  ImageViewer: require("./ImageViewer.jsx"),
  Label: require("./Label.jsx"),
  MoneyEntry: require("./MoneyEntry.jsx"),
  Quote: require("./Quote.jsx"),
  SaveAndCancelControls: require("./SaveAndCancelControls.jsx"),
  Select: require("./Select.jsx"),
  SmallGroupedItemSelector: require("./SmallGroupedItemSelector.jsx"),
  StillForm: require("./StillForm.jsx"),
  TextInput: require("./TextInput.jsx"),
  Time: require("./Time.jsx"),
  TimeWithZone: require("./TimeWithZone.jsx"),
  TimePicker: require("./TimePicker.jsx"),
  TimeStamp: require("./TimeStamp.jsx"),
  ViewField: require("./ViewField.jsx"),
  ViewMoney: require("./ViewMoney.jsx"),
};

components.domain = {
  ActiveAndInactiveField: require("./domain/ActiveAndInactiveField.jsx"),
  AdMention: require("./domain/AdMention.jsx"),
  AddressDisplay: require("./domain/AddressDisplay.jsx"),
  AddressEditor: require("./domain/AddressEditor.jsx"),
  AddressEntryDisplay: require("./domain/AddressEntryDisplay.jsx"),
  AddressForm: require("./domain/AddressForm.jsx"),
  AddressHistoryDisplay: require("./domain/AddressHistoryDisplay.jsx"),
  AnnouncementMention: require("./domain/AnnouncementMention.jsx"),
  AppointmentMention: require("./domain/AppointmentMention.jsx"),
  ContactDisplay: require("./domain/ContactDisplay.jsx"),
  ContactHistoryDisplay: require("./domain/ContactHistoryDisplay.jsx"),
  ContactEditor: require("./domain/ContactEditor.jsx"),
  ContactForm: require("./domain/ContactForm.jsx"),
  ContractMention: require("./domain/ContractMention.jsx"),
  CSVDownload: require("./domain/CSVDownload.jsx"),
  EmployeeMention: require("./domain/EmployeeMention.jsx"),
  ExtensionMention: require("./domain/ExtensionMention.jsx"),
  GoogleMapsLink: require("./domain/GoogleMapsLink.jsx"),
  LeadMention: require("./domain/LeadMention.jsx"),
  MiniExtensionList: require("./domain/MiniExtensionList.jsx"),
  NotesDisplay: require("./domain/NotesDisplay.jsx"),
  NotesForm: require("./domain/NotesForm.jsx"),
  OfferTree: require("./domain/OfferTree.jsx"),
  FullOfferName: require("./domain/FullOfferName.jsx"),
  PeopleNames: require("./domain/PeopleNames.jsx"),
  ProjectMention: require("./domain/ProjectMention.jsx"),
  SourceMention: require("./domain/SourceMention.jsx"),
  SourceOriginMention: require("./domain/SourceOriginMention.jsx"),
  SourceTypeMention: require("./domain/SourceTypeMention.jsx"),
  SeriesForm: require("./domain/SeriesForm.jsx"),
  TerritoryForm: require("./domain/TerritoryForm.jsx"),
  TerritoryOutline: require("./domain/TerritoryOutline.jsx"),
};

components.makers = {
  Pager: require("./makers/MakePager.jsx"),
};

module.exports = components;
