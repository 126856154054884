const React = require("react");

const FieldGroup = require("./../../FieldGroup.jsx");
const Field = require("./../../Field.jsx");
const Label = require("./../../Label.jsx");

module.exports = React.createClass({
  displayName: "PlacementsHeader",
  shouldComponentUpdate: function (nextProps, nextState) {
    return true;
  },
  render: function () {
    const { showOnlyIf, showNoteColumn, expanded } = this.props;
    if (!showOnlyIf) {
      return <span />;
    }

    return (
      <FieldGroup
        className={
          (showNoteColumn ? "maxcols-32" : "maxcols-26") + " flush-right"
        }
      >
        <FieldGroup className="maxcols-26 flush-right">
          <Field className="maxcols-7">
            <Label
              showOnlyIf={expanded}
              className="maxcols-padded-7 emphasized"
              text="Project"
            />
          </Field>
          <Field className="maxcols-4">
            <Label
              className="maxcols-padded-3 to-right emphasized"
              text="Budget"
            />
          </Field>
          <Field className="maxcols-3">
            <Label
              className="maxcols-padded-3 to-right emphasized"
              text="Ad Fee"
            />
          </Field>
          <Field className="maxcols-3">
            <Label className="to-right emphasized" text="Placement" />
          </Field>
          <Field className="maxcols-3">
            <Label className="to-right emphasized" text="Cancel" />
          </Field>
          <Field className="maxcols-3">
            <Label className="to-right emphasized" text="Other" />
          </Field>
          <Field className="maxcols-3">
            <Label
              className="maxcols-padded-3 to-right emphasized"
              text="Final Cost"
            />
          </Field>
        </FieldGroup>
        <FieldGroup
          showOnlyIf={showNoteColumn}
          className="maxcols-6 flush-right"
        >
          <Field className="maxcols-6">
            <Label className="maxcols-padded-6 emphasized" text="Notes" />
          </Field>
        </FieldGroup>
      </FieldGroup>
    );
  },
});
