const Anchor = require("./../Anchor.jsx");
const Time = require("./../Time.jsx");

module.exports = ({
    appointment,
    link,
    inline,
    displayStyle,
    className,
    remainder
}) => {
    module.exports.displayName = "AppointmentMention";

    const id = appointment.id.toString();
    const display_text = build_display_text(displayStyle, appointment);

    if (link) {
        const classes = lib.handle_classes(className, "item.link");
        const show_remainder = lib.coalesce(remainder, "");
        return (
            <Anchor
                className={classes}
                href={`${standard.URL.appointments.pages}?item=${
                    appointment.id
                }${show_remainder}`}>
                {display_text}
            </Anchor>
        );
    }
    if (inline) {
        return <span className={className}>{display_text}</span>;
    }
    return <div className={className}>{display_text}</div>;
};

const build_display_text = (style, appointment) => {
    const DisplayOptions = {
        date: app => {
            return <Time timestamp={appointment.date} />;
        },
        summary: app => lib.coalesce(app.summary, "...")
    };

    const cleaned_style = lib.coalesce(style, "").toLowerCase();

    return DisplayOptions.hasOwnProperty(cleaned_style)
        ? DisplayOptions[cleaned_style](appointment)
        : DisplayOptions["date"](appointment);
};
