const Select = require("./Select.jsx");
const Dump = require("./Dump.jsx");

const build_steps = (minute_resolution, working, offset) => {
    const range = x => [...Array(x).keys()];
    const format_minute = m => ":" + `0${m}`.slice(-2);
    const steps = {
        hours: range(24).map(hour => {
            const show_hour = hour > 12 ? hour - 12 : hour === 0 ? 12 : hour;
            return {value: hour, display: show_hour.toString()};
        }),
        minutes: range(60 / minute_resolution)
            .map(m => m * minute_resolution)
            .map(m => {
                return {value: m, display: format_minute(m)};
            }),
        meridiem: lib.options.from_plain(["AM", "PM"])
    };
    const minutes = steps.minutes.map(m => m.value);
    if (minutes.indexOf(working.minutes) === -1) {
        steps.minutes = [
            ...steps.minutes,
            {value: working.minutes, display: format_minute(working.minutes)}
        ];
        steps.minutes.sort((a, b) => {
            return lib.cmp(a.value, b.value);
        });
    }

    return steps;
};

const build_updater = (time, working, offset, updateAction) => {
    const {moment} = lib.third_party;
    return field => {
        return value => {
            working[field] = value;
            if (field === "meridiem") {
                if (working.meridiem === "AM" && working.hours > 11) {
                    working.hours -= 12;
                }
                if (working.meridiem === "PM" && working.hours < 12) {
                    working.hours += 12;
                }
            }
            lib.time.obj.apply(time, working);
            if (offset !== 0) {
                time = moment(time)
                    .add(offset, "m")
                    .toDate();
            }
            updateAction(lib.time.to_stamp(time));
        };
    };
};

module.exports = ({
    timestamp,
    updateAction,
    className,
    error,
    minuteResolution = 1,
    offset = 0,
    disabled = undefined
}) => {
    module.exports.displayName = "TimePicker";

    const classes = lib.handle_classes(className, "field.multi");

    const time =
        timestamp === undefined ? lib.time.today() : new Date(timestamp);
    const working = {
        hours: time.getHours(),
        minutes: time.getMinutes(),
        seconds: time.getSeconds(),
        meridiem: time.getHours() < 12 ? "AM" : "PM"
    };
    const steps = build_steps(minuteResolution, working);
    const updater = build_updater(time, working, offset, updateAction);

    return (
        <div className={classes}>
            <Select
                className="maxcols-padded-2 field.part"
                options={steps.hours}
                value={working.hours}
                updateAction={updater("hours")}
                error={error}
                disabled={disabled}
            />
            {steps.minutes.length > 1 ? (
                <Select
                    className="maxcols-padded-2 field.part"
                    options={steps.minutes}
                    value={working.minutes}
                    updateAction={updater("minutes")}
                    error={error}
                    disabled={disabled}
                />
            ) : (
                <span />
            )}
            <Select
                className="maxcols-padded-2 field.part"
                options={steps.meridiem}
                value={working.meridiem}
                updateAction={updater("meridiem")}
                error={error}
                disabled={disabled}
            />
        </div>
    );
};
