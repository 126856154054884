module.exports = ({text, id, className}) => {
    module.exports.displayName = "Quote";
    const classes = lib.handle_classes(className, "quote");
    const base_id = id.toString();
    let clean_text = text
        .trim()
        .split("\n")
        .map(p => p.trim())
        .join("\n");
    while (clean_text.indexOf("\n\n\n") !== -1) {
        clean_text = clean_text.replace("\n\n\n", "\n\n");
    }
    const paragraphs = clean_text.trim().split("\n");
    let in_list = false;

    return (
        <blockquote className={classes}>
            {paragraphs.map((p, i) => {
                const key = `${base_id}-${i}`;
                if (p !== "") {
                    in_list = p.startsWith("*");
                } else {
                    if (in_list) {
                        return <span key={key} />;
                    }
                }
                return (
                    <p className="flush-left" key={key}>
                        {p || <br />}
                    </p>
                );
            })}
        </blockquote>
    );
};
