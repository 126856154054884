const Dump = require("./../Dump.jsx");
const Accordion = require("./../Accordion.jsx");
const AccordionControl = require("./../AccordionControl.jsx");
const FieldGroup = require("./../FieldGroup.jsx");

const ExtensionSeries = require("./series/ExtensionSeries.jsx");
const SourceSeries = require("./series/SourceSeries.jsx");
const AdSeries = require("./series/AdSeries.jsx");

const SeriesInnerForm = ({
  series,
  territory,
  updateAction,
  toggles,
  toggleAction,
  errors,
}) => {
  module.exports.displayName = "SeriesForm";
  const { level, entries } = series;
  const updater = lib.updaters.wrapping({
    object: series,
    action: updateAction,
  });
  if (level === "extension") {
    return (
      <ExtensionSeries
        entries={entries}
        startDate={series.min_start_date}
        territory={territory}
        toggles={toggles}
        toggleAction={toggleAction}
        updateAction={updater("entries")}
        errors={errors}
      />
    );
  }
  if (level === "source") {
    return (
      <SourceSeries
        entries={entries}
        territory={territory}
        toggles={toggles}
        toggleAction={toggleAction}
        updateAction={updater("entries")}
        errors={errors}
      />
    );
  }
  if (level === "ad") {
    return (
      <AdSeries
        entries={entries}
        toggles={toggles}
        toggleAction={toggleAction}
        updateAction={updater("entries")}
        errors={errors}
      />
    );
  }
  return <Dump data={series} />;
};

module.exports = ({
  series,
  territory,
  updateAction,
  errors,
  toggles,
  toggleAction,
}) => {
  module.exports.displayName = "SeriesForm";
  if (series === undefined) {
    return <span />;
  }
  const toggler = lib.updaters.make(toggleAction);

  return (
    <FieldGroup className="maxcols-40">
      <AccordionControl
        className="to-left"
        show={toggles.series}
        showAction={toggler("series")}
        legend={true}
      >
        Series
      </AccordionControl>
      <Accordion open={toggles.series} large={true} className="maxcols-40">
        <SeriesInnerForm
          series={series}
          territory={territory}
          toggles={toggles}
          toggleAction={toggleAction}
          updateAction={updateAction}
          errors={errors}
        />
      </Accordion>
    </FieldGroup>
  );
};
