import "core-js/stable";
import "regenerator-runtime/runtime";

import React from "react";

import Fluxxor from "fluxxor";

import { render } from "react-dom";

import DayPicker from "react-day-picker";
import DayPickerInput from "react-day-picker/DayPickerInput";

// export for other scripts to use
window.Fluxxor = Fluxxor;
window.React = React;
window.render = render;

import { setup_ui_enhancements } from "./libraries/ui_enhancements.js";
import { setup_form_helpers } from "./libraries/form-helpers.js";

import * as lib from "./libraries";
window.lib = lib;

import * as components from "./components";

window.api = lib.api;
window.components = components;
window.components.third_party = { DayPicker, DayPickerInput };
window.domain = lib.domain;
window.helpers = lib.helpers;
window.lib = lib;
window.models = lib.models;
window.query_params = lib.query_string_to_json();
window.standard = lib.standard;
window.tests = lib.validation.Tests;
window.ui = lib.ui;
window.POSTing = false;

var set_flash = (theHtml) => {
  $("#Page-Flashes")
    .hide()
    .empty()
    .addClass("is-visible")
    .prepend(theHtml)
    .show()
    .fadeOut(4000);
};
window.alert = (text) => {
  set_flash('<div role="alert" class="item warning-blurb">' + text + "</div>");
};
window.status = (text) => {
  set_flash('<div role="status" class="item status-blurb">' + text + "</div>");
};

const lay_out_menu = () => {
  const items = $("li.main-nav-menu-item");
  const menu_width = $(".main-nav-menu:first").width();
  const col_size = 32;
  const columns = menu_width / col_size;

  if (columns < 24) {
    for (let i = 0; i < items.length; i++) {
      items[i].className = "main-nav-menu-item";
    }
    return;
  }

  let remaining = columns + 0;
  let cols = 0;
  const Minimums = {
    "nav-financing": 4,
  };
  const classes = [];
  for (let i = items.length - 1; i > -1; i--) {
    cols = Math.floor(remaining / (i + 1));
    if (Minimums[items[i].id] !== undefined) {
      cols = Math.max(Minimums[items[i].id], cols);
    }
    classes[i] = `main-nav-menu-item maxcols-${cols}`;
    remaining -= cols;
  }
  for (let i = 0; i < classes.length; i++) {
    items[i].className = classes[i];
  }
};

const setup_user_info = () => {
  window.user_info = {};
  const site_user = $(".site-user");
  if (site_user.length > 0) {
    window.user_info = {
      name: site_user.attr("data-name"),
      role: site_user.attr("data-role"),
    };
    if (lib.its_me()) {
      site_user.click(() => {
        $("body").toggleClass("debug-layout");
      });
    }
  }
};

$.ajaxSetup(standard.ajax_setup);

lib.ready(() => {
  // lay_out_menu();
  // window.onresize = lay_out_menu;

  setup_ui_enhancements();
  setup_form_helpers();
  lib.validation.setup_form_validations();

  setup_user_info();
});
