const AccordionDisplay = require("./AccordionDisplay.jsx");
const Anchor = require("./Anchor.jsx");

module.exports = ({show, showAction, className, children, legend = false}) => {
    module.exports.displayName = "AccordionControl";
    if (legend) {
        const classes = lib.handle_classes(className, "item");
        return (
            <legend className={classes} style={{width: "100%"}}>
                <Anchor className="menu-item.link to-left" action={showAction}>
                    {children} <AccordionDisplay open={show} />
                </Anchor>
            </legend>
        );
    }
    return (
        <Anchor className={className} action={showAction}>
            {children} <AccordionDisplay open={show} />
        </Anchor>
    );
};
