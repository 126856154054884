import * as basic from "./basic.js";
import * as fluxing from "./fluxing.js";
import { numbers } from "./numbers.js";

const moment = require("moment");

const reformat_time = (locale_string) => {
  const raw = locale_string.replace(/:/g, " ").toLowerCase();
  const parts = raw.split(" ");
  const time_parts = [parts[0] + ":", parts[1], parts[3]];
  return time_parts.join("");
};

const date_and_time = {
  today: () => {
    const now = new Date();
    return new Date(now.getFullYear(), now.getMonth(), now.getDate());
  },
  now: () => {
    return new Date();
  },

  to_stamp: (dt) => {
    // returns *Javascript* timestamp, not unix
    if (dt === undefined) {
      return dt;
    }
    return dt.getTime();
  },
  from_stamp: (stamp) => {
    // expects *Javascript* timestamp, not unix
    if (stamp === undefined) {
      return stamp;
    }
    return new Date(stamp);
  },
  from_iso: (s) => {
    if ((s ?? "") === "") {
      return undefined;
    }
    const parts = s.split("-").map((x) => parseInt(x, 10));
    return new Date(parts[0], parts[1] - 1, parts[2]);
  },
  as_stamp: (raw) => {
    const { to_stamp } = date_and_time;
    if (typeof raw === "number") {
      return raw;
    }
    if (typeof raw === "object") {
      return to_stamp(raw);
    }
    if (typeof raw === "string") {
      return date_and_time.cleanup(raw);
    }
    return undefined;
  },
  cleanup: (raw) => {
    const val = basic.str.remove_directionals(raw);
    const dt = new Date(val);
    if (isNaN(dt.getFullYear())) {
      return undefined;
    }
    return date_and_time.to_stamp(dt);
  },
  format: {
    normal: (dt) => {
      if (dt === undefined) {
        return "";
      }
      const { plain, smart } = date_and_time.format;
      const raw_date = plain.date(dt);
      const the_date = raw_date === "Today" ? "" : raw_date;
      const raw_time = smart.time(dt);
      const the_time = raw_time === "12:00am" ? "" : raw_time;
      return (the_date + " " + the_time).trim();
    },
    precise: (dt) => {
      if (dt === undefined) {
        return "";
      }
      const today = new Date().toLocaleDateString();
      const date_of = dt.toLocaleDateString();
      const the_time = dt.toLocaleTimeString();
      if (date_of == today) {
        return the_time;
      }
      return date_of + " " + the_time;
    },
    iso: (dt) => {
      // Local iso date, *not* UTC
      const parts = [dt.getFullYear(), dt.getMonth() + 1, dt.getDate()];
      return parts.map((x) => x.toString().padStart(2, "0")).join("-");
    },

    relative: {
      time: (dt) => {
        const raw = moment(dt).fromNow();
        return raw;
      },
      days: (dt) => {
        const days = moment().diff(moment(dt), "days");
        const nice_days = numbers.int_format(Math.abs(days));
        const direction = days >= 0 ? "ago" : "from now";
        return [nice_days, days === 1 ? "day" : "days", direction].join(" ");
      },
    },
    elapsed: {
      days: (dt) => {
        const diff = (units) => moment().diff(moment(dt), units);
        const days = diff("days");
        if (days < 0) {
          return "";
        }
        if (days < 1) {
          return "for less than a day";
        }
        return [
          "for",
          numbers.int_format(days),
          numbers.pluralize(days, "day", "days"),
        ].join(" ");
      },
      days_plus: (dt) => {
        const { pluralize } = numbers;
        const diff = (units) => moment().diff(moment(dt), units);

        const days = diff("days");
        if (days < 0) {
          return "";
        }
        if (days < 1) {
          return "for less than a day";
        }
        if (days < 100) {
          return `for ${days} ${pluralize(days, "day", "days")}`;
        }

        const months = diff("months");
        if (months < 24) {
          return `for ${months} ${pluralize(months, "month", "months")}`;
        }

        const years = diff("years");
        return `for ${years} ${pluralize(years, "year", "years")}`;
      },
    },
    remaining: {
      days: (dt) => {
        const days = moment().diff(moment(dt), "days");
        const nice_days = numbers.int_format(Math.abs(days));
        if (days > 0) {
          return "";
        }
        return `${nice_days} days remaining`;
      },
    },

    plain: {
      date: (dt) => {
        if (dt === undefined) {
          return "";
        }
        return dt.toLocaleDateString();
      },
      time: (dt) => {
        if (dt === undefined) {
          return "";
        }
        return reformat_time(dt.toLocaleTimeString());
      },
    },
    plainly: (dt) => {
      if (dt === undefined) {
        return "";
      }
      const { plain } = date_and_time.format;
      return `${plain.date(dt)} ${plain.time(dt)}`;
    },
    smart: {
      date: (dt) => {
        if (dt === undefined) {
          return "";
        }
        const today = new Date().toLocaleDateString();
        const date_of = dt.toLocaleDateString();
        if (date_of == today) {
          return "Today";
        }
        return date_of;
      },
      time: (dt) => {
        if (dt === undefined) {
          return "";
        }
        return reformat_time(dt.toLocaleTimeString());
      },
    },
    day_of_week: (dt) => {
      return [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ][dt.getDay()];
    },
  },

  util: {
    stamp_to_str: (js_timestamp) => {
      if (js_timestamp === undefined) {
        return undefined;
      }
      const { format, from_stamp } = date_and_time;
      const result = format.plain.date(from_stamp(js_timestamp));
      return result === "Invalid Date" ? "" : result;
    },
    stamp_to_iso: (js_timestamp) => {
      if (js_timestamp === undefined) {
        return undefined;
      }
      const { format, from_stamp } = date_and_time;
      const result = format.iso(from_stamp(js_timestamp));
      return result === "Invalid Date" ? "" : result;
    },
    str_to_stamp: (date_str) => {
      const { to_stamp } = date_and_time;
      return to_stamp(date.parse(date_str));
    },
    iso_to_stamp: (iso_str) => {
      if ((iso_str ?? "") === "") {
        return undefined;
      }
      const { from_iso, to_stamp } = date_and_time;
      return to_stamp(from_iso(iso_str));
    },
    naive_iso_to_str: (iso_str) => {
      if ((iso_str ?? "") === "") {
        return undefined;
      }
      const parts = iso_str.split("-");
      return [parts[1], parts[2], parts[0]].join("/");
    },
  },

  add: {
    period: (js_timestamp, set_method, get_method, amount) => {
      const { from_stamp, to_stamp } = date_and_time;
      const dt = from_stamp(js_timestamp);
      dt[set_method](dt[get_method]() + amount);
      return to_stamp(dt);
    },
    years: (js_timestamp, amount) => {
      const { from_stamp, to_stamp } = date_and_time;
      const dt = from_stamp(js_timestamp);
      dt.setFullYear(dt.getFullYear() + amount);
      return to_stamp(dt);
    },
    months: (js_timestamp, amount) => {
      const { from_stamp, to_stamp } = date_and_time;
      const dt = from_stamp(js_timestamp);
      const result = moment(dt).add(amount, "months").toDate();
      return to_stamp(result);
    },
    weeks: (js_timestamp, amount) => {
      const { from_stamp, to_stamp } = date_and_time;
      const dt = from_stamp(js_timestamp);
      dt.setDate(dt.getDate() + amount * 7);
      return dt;
    },
    days: (js_timestamp, amount) => {
      const { from_stamp, to_stamp } = date_and_time;
      const dt = from_stamp(js_timestamp);
      dt.setDate(dt.getDate() + amount);
      return dt;
    },
  },

  day: {
    begin: (js_timestamp) => {
      const { from_stamp, to_stamp } = date_and_time;
      const dt = from_stamp(js_timestamp);
      dt.setHours(0);
      dt.setMinutes(0);
      dt.setSeconds(0);
      return dt;
    },
    end: (js_timestamp) => {
      const { from_stamp, to_stamp } = date_and_time;
      const dt = from_stamp(js_timestamp);
      dt.setHours(23);
      dt.setMinutes(59);
      dt.setSeconds(59);
      return dt;
    },
    same: (target, possible) => {
      const { stamp_to_iso } = date_and_time.util;
      return stamp_to_iso(target) === stamp_to_iso(possible);
    },
  },
  week: {
    begin: (js_timestamp) => {
      const { from_stamp, to_stamp, day, add } = date_and_time;
      const dt = day.begin(from_stamp(js_timestamp));

      return from_stamp(add.days(dt, -1 * dt.getDay()));
    },
    end: (js_timestamp) => {
      const { from_stamp, to_stamp, day, add } = date_and_time;
      const dt = day.end(from_stamp(js_timestamp));

      return from_stamp(add.days(dt, 6 - dt.getDay()));
    },
    same: (target, possible) => {
      const { week, format } = date_and_time;
      const week_of = (x) => format.iso(week.begin(x));
      return week_of(target) === week_of(possible);
    },
  },
  month: {
    begin: (js_timestamp) => {
      const { from_stamp, to_stamp, day } = date_and_time;
      const dt = from_stamp(day.begin(from_stamp(js_timestamp)));
      dt.setDate(1);

      return dt;
    },
    end: (js_timestamp) => {
      const { from_stamp, to_stamp } = date_and_time;
      const dt = from_stamp(js_timestamp);
      const mo = moment(dt);

      return mo.endOf("month").toDate();
    },
    same: (target, possible) => {
      const { month, format } = date_and_time;
      const month_of = (x) => format.iso(month.begin(x));
      return month_of(target) === month_of(possible);
    },
  },
};

const date = {
  today: date_and_time.today,
  now: date_and_time.now,
  to_stamp: date_and_time.to_stamp,
  from_stamp: date_and_time.from_stamp,
  from_iso: date_and_time.from_iso,
  to_iso: date_and_time.format.iso,
  format: date_and_time.format,
  util: date_and_time.util,
  add: date_and_time.add,
  day: date_and_time.day,
  week: date_and_time.week,
  month: date_and_time.month,

  parse: (raw, format = "") => {
    const val = basic.str.remove_directionals(raw);
    const inner_parse = (x, format) => {
      return moment(x, format, true).toDate();
    };
    if (format === "") {
      const parts = val
        .replace("-", "/")
        .split("/")
        .map((p) => p.trim())
        .filter((p) => p !== "");
      if (parts.length < 2) {
        return undefined;
      }
      if (parts.length === 2) {
        return inner_parse(val, "M/D");
      }
      if (parts[2].length === 2) {
        return inner_parse(val, "M/D/YY");
      }
      return inner_parse(val, "M/D/YYYY");
    }
    return inner_parse(val, format);
  },
};

const time = {
  today: date_and_time.today,
  now: date_and_time.now,
  to_stamp: date_and_time.to_stamp,
  from_stamp: date_and_time.from_stamp,
  as_stamp: date_and_time.as_stamp,
  format: date_and_time.format,
  util: date_and_time.util,

  obj: {
    apply: (dt, obj) => {
      ["hours", "minutes", "seconds"].forEach((field) => {
        if (obj[field] !== undefined) {
          dt[`set${basic.str.title_case(field)}`](obj[field]);
        }
      });
    },
    save: (dt) => {
      if (dt.getHours === undefined || isNaN(dt.getHours())) {
        return undefined;
      }
      return {
        hours: dt.getHours(),
        minutes: dt.getMinutes(),
        seconds: dt.getSeconds(),
      };
    },
  },

  zones: {
    local_offset: () => {
      return -1 * new Date().getTimezoneOffset();
    },
    relative_offset: (time_zone) => {
      const local = time.zones.local_offset();
      return time_zone === undefined ? 0 : local - time_zone.offset;
    },
    default_time_zone: (supplied, options) => {
      if (supplied !== undefined) {
        return supplied;
      }
      if ((options ?? []).length === 0) {
        return undefined;
      }
      const local = time.zones.local_offset();
      return options.find((x) => x.offset === local);
    },
    display_timestamp: (timestamp, time_zone) => {
      const local = time.zones.local_offset();
      const different_time_zone =
        timestamp !== undefined &&
        time_zone !== undefined &&
        time_zone.offset !== local;
      return different_time_zone
        ? timestamp + (time_zone.offset - local) * 60 * 1000
        : timestamp;
    },
    setup_options: (raw_options, selected) => {
      const local = time.zones.local_offset();

      const results = fluxing.options
        .build_list_multi(raw_options ?? [])
        .map((x) => {
          const result = basic.obj.clone(x);
          if (result.actual.offset === local) {
            result.display = `Local: ${result.display}`;
          }
          return result;
        });
      if (selected === undefined) {
        return results;
      }
      return results.filter((x) => x.value !== "");
    },
    make_sub_updater: (updateAction, time_zone) => {
      const minutes = 60 * 1000;
      return (field, sub_field, original_value) => {
        const value = original_value ?? {};
        return (sub_value) => {
          value[sub_field] = sub_value;
          updateAction(field, value);

          if (sub_field === "time_zone") {
            updateAction(
              [...field, "timestamp"],
              value.timestamp + (time_zone.offset - sub_value.offset) * minutes
            );
          }
          updateAction(
            [...field, "display_timestamp"],
            time.zones.display_timestamp(value.timestamp, value.time_zone)
          );
        };
      };
    },
  },
};

module.exports = {
  date,
  time,
};
