import { time } from "./../../libraries/";

const Time = require("./../Time.jsx");

module.exports = ({ entry }) => {
  module.exports.displayName = "AddressEntryDisplay";
  const Fields = ["company", "street", "city", "state", "zip"];
  if (!entry) {
    return <br />;
  }
  const blank = Fields.every((field, i, ray) => {
    return (entry[field] || "").toString().trim() == "";
  });

  if (blank) {
    return (
      <div className="text">
        <Time timestamp={entry.recorded} className="header-time" />
        <br />
        <i>(blank)</i>
      </div>
    );
  }
  return (
    <div>
      <div className="text">
        <Time timestamp={entry.recorded} className="header-time" />
      </div>
      <div className="text">
        {entry.company}
        {entry.company ? <br /> : null}
        {entry.street || <span>&mdash;</span>}
        <br />
        {entry.city}, {entry.state} {entry.zip}
      </div>
    </div>
  );
};
