const React = require("react");
const Anchor = require("./Anchor.jsx");


module.exports = React.createClass({
    displayName:"EditTrigger",
    handleClick:function(e) {
        e.preventDefault();
        if (this.props.onClick !== undefined) {
            this.props.onClick(e);
        }
    },

    render:function() {
        var children = this.props.children;
        var classes = this.props.className || "edit-trigger";
        if (classes.indexOf("edit-trigger") == -1) {
            classes += " edit-trigger";
        }
        return (
            <Anchor className={classes} title={this.props.title || "Click to Edit"}
                    onClick={this.handleClick}>
                {React.Children.map(children, (child) => {return child;})}
            </Anchor>
        );
    }
});
