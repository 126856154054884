var React = require("react");
var Fluxxor = require("fluxxor");

import * as api from "./../../libraries/api.js";

module.exports = function(page_url, store_name) {
    return React.createClass({
        displayName:`${store_name}Pager`,
        mixins: [Fluxxor.FluxMixin(React), Fluxxor.StoreWatchMixin(store_name)],
        getStateFromFlux: function() {
            return this.getFlux()
                .store(store_name)
                .getState();
        },

        handlePaging: function(e) {
            e.preventDefault();
            api.push_state_to(e.target.href);
            this.getFlux().actions.change_position();
        },

        render: function() {
            const length = this.state.listing.length;
            const page_size = this.state.page_size;
            const position = this.state.position;
            const page = Math.ceil((position + 1) / page_size);
            const pages = Math.ceil(length / page_size);

            const nav = {
                first: 0,
                prior: Math.max(position - page_size, 0),
                next: position + page_size,
                here: position,
                last: (Math.ceil(length / page_size) - 1) * page_size
            };

            if (length <= page_size) {
                return <span />;
            }
            const url = index => {
                return `#position=${index}`;
            };
            return (
                <div className="pager">
                    <ol className="pager-list">
                        <li className="pager-item pager.backward">
                            {position > 0 ? (
                                <a
                                    className="pager-item.link"
                                    href={url(nav.first)}
                                    onClick={this.handlePaging}
                                    data-target={nav.first}>
                                    Page 1
                                </a>
                            ) : (
                                <div className="pager-item.stub">Page 1</div>
                            )}
                        </li>
                        <li className="pager-item pager.backward">
                            {position > 0 ? (
                                <a
                                    className="pager-item.link"
                                    href={url(nav.prior)}
                                    onClick={this.handlePaging}
                                    data-target={nav.prior}>
                                    &lt;&lt; Prev
                                </a>
                            ) : (
                                <div className="pager-item.stub">
                                    &lt;&lt; Prev
                                </div>
                            )}
                        </li>
                        <li className="pager-item">
                            <div className="page-item.stub to-center">
                                <br />
                                Page {page.toLocaleString()}
                                <br />
                                {length.toLocaleString()} records
                            </div>
                        </li>
                        <li className="pager-item pager.forward">
                            {nav.next < length ? (
                                <a
                                    className="pager-item.link"
                                    href={url(nav.next)}
                                    onClick={this.handlePaging}
                                    data-target={nav.next}>
                                    Next &gt;&gt;
                                </a>
                            ) : (
                                <div className="pager-item.stub">
                                    Next &gt;&gt;
                                </div>
                            )}
                        </li>
                        <li className="pager-item pager.forward">
                            {nav.next < length ? (
                                <a
                                    className="pager-item.link"
                                    href={url(nav.last)}
                                    onClick={this.handlePaging}
                                    data-target={nav.last}>
                                    Page {pages.toLocaleString()}
                                </a>
                            ) : (
                                <div className="pager-item.stub">
                                    Page {pages.toLocaleString()}
                                </div>
                            )}
                        </li>
                    </ol>
                </div>
            );
        }
    });
};
