import { coalesce, obj } from "./basic.js";

const name_component = (displayName, component) => {
  component.displayName = displayName;
  return component;
};

const dash_or = (item, follows = undefined) => {
  if ((item || "") === "") {
    return <span>&mdash;</span>;
  }
  if (follows === undefined) {
    return item;
  }
  if (typeof follows === "function") {
    return follows(item);
  }
  if (typeof item === "object" && typeof follows == "string") {
    const result = item[follows];
    if (result !== undefined) {
      return result;
    }
    return <span>&mdash;</span>;
  }
  return item;
};

const dash_or_ellipsis = (s, length = 40) => {
  if (coalesce(s, "") === "") {
    return dash_or("");
  }
  if (s.length <= length) {
    return s;
  }
  return <span>{s.substr(0, length)}&hellip;</span>;
};

const extant_action = (act) => {
  return coalesce(act, (...args) => {});
};

const handle_if_extant = (act) => {
  return (e) => {
    e.preventDefault();
    extant_action(act)();
  };
};

const build_upload_form = (action_key, item, uploads_key_path) => {
  const form = new FormData();
  form.append(action_key, JSON.stringify(item));

  const pos = obj.path_lookup(item, uploads_key_path) ?? [];
  pos.forEach((att, i) => {
    form.append(`file_upload-${i}`, att.file.info);
  });

  return form;
};

module.exports = {
  build_upload_form,
  dash_or,
  dash_or_ellipsis,
  extant_action,
  handle_if_extant,
  name_component,
};
