const Anchor = require("./../Anchor.jsx");

module.exports = ({
	ad,
	link,
	inline,
	showOnlyIf,
	className,
  displayText
}) => {
	module.exports.displayName = "AdMention";
	
	if (!lib.coalesce(showOnlyIf, true)) {
		return <span />;
	}
	if (ad === undefined || ad.id === undefined) {
		return <span />;
	}

	const this_offer = lib.coalesce(ad.offer, "").toString().trim();
	const offer = (this_offer === "" ? "(blank offer)" : <q>{this_offer}</q>);
  const contents = displayText === undefined ? offer : displayText;
	if (link) {
		const classes = lib.handle_classes(className, "item.link");
		return (
			<Anchor className={classes}
					href={`${standard.URL.ads.pages}?item=${ad.id}`}>
				{contents}
			</Anchor>
		);
	}
	if (inline) {
		return <span className={className}>{contents}</span>;
	}
	return <div className={className}>{contents}</div>;
}
