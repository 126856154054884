const Dump = require("./../Dump.jsx");
const Field = require("./../Field.jsx");
const Label = require("./../Label.jsx");
const TextInput = require("./../TextInput.jsx");

module.exports = ({contact, updateAction, debug = false}) => {
    module.exports.displayName = "ContactEditor";
    const {home_phone, work_phone, mobile_phone, fax, other, email} = contact;
    const {wrapping} = lib.updaters;
    const updater = wrapping({
        object: contact,
        action: updateAction,
        object_transform: info => {
            const result = lib.obj.clone(info);
            result.blank =
                lib.obj.all_blank(result, [
                    "home_phone",
                    "work_phone",
                    "mobile_phone",
                    "fax",
                    "email"
                ]) && lib.obj.all_blank(other, ["name", "number"]);
            return result;
        }
    });
    const other_updater = wrapping({object: other, action: updater("other")});

    return (
        <div>
            <Field>
                <Label text="Home:" />
                <TextInput
                    value={home_phone}
                    placeholder="Number"
                    className="maxcols-padded-5"
                    updateAction={updater("home_phone")}
                    maxLength={20}
                />
            </Field>
            <Field>
                <Label text="Work:" />
                <TextInput
                    value={work_phone}
                    placeholder="Number"
                    className="maxcols-padded-5"
                    updateAction={updater("work_phone")}
                    maxLength={20}
                />
            </Field>
            <Field>
                <Label text="Mobile:" />
                <TextInput
                    value={mobile_phone}
                    placeholder="Number"
                    className="maxcols-padded-5"
                    updateAction={updater("mobile_phone")}
                    maxLength={20}
                />
            </Field>
            <Field>
                <Label text="Fax:" />
                <TextInput
                    value={fax}
                    placeholder="Number"
                    className="maxcols-padded-5"
                    updateAction={updater("fax")}
                    maxLength={20}
                />
            </Field>
            <Field>
                <TextInput
                    value={other.name}
                    placeholder="Other"
                    className="maxcols-padded-3"
                    updateAction={other_updater("name")}
                />
                <TextInput
                    value={other.number}
                    placeholder="Number"
                    className="maxcols-padded-5"
                    updateAction={other_updater("number")}
                />
            </Field>
            <Field>
                <Label text="Email:" />
                <TextInput
                    value={email}
                    placeholder="Address"
                    className="maxcols-padded-9"
                    updateAction={updater("email")}
                    maxLength={260}
                />
            </Field>

            <Dump showOnlyIf={debug} data={contact} />
        </div>
    );
};
